import { create } from 'zustand';
import { devtools, persist, subscribeWithSelector } from 'zustand/middleware';

import createAppSlice, { AppSlice } from './createAppSlice';
import createSearchSlice, { CreateSearchSlice } from './createSearchSlice';
import createUISlice, { CreateUISlice } from './createUISlice';
import createUserSlice, { CreateUserSlice } from './createUserSlice';

export type State = CreateSearchSlice & CreateUserSlice & CreateUISlice & AppSlice;

const useStore = create<State>()(
  devtools(
    persist(
      subscribeWithSelector((...args) => ({
        ...createSearchSlice(...args),
        ...createUserSlice(...args),
        ...createAppSlice(...args),
        ...createUISlice(...args),
      })),
      {
        name: 'ontee-storage',
      }
    )
  )
);

export default useStore;
