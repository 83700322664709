import { useMutation } from 'react-query';

import { post } from '../../http';

const subscribeToNewsletter = async (email: string): Promise<void> => {
  await post('Account/RegisterNewsletterUser', { Email: email });
};

export const useSubscribeToNewsletter = () =>
  useMutation('newsletter-subscription', subscribeToNewsletter);
