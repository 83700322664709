import { useMemo } from 'react';
import { matchRoutes, useLocation } from 'react-router-dom';

import { useAccommodationFilter } from '../../../hooks/useAccommodationFilter';
import useCities from '../cities/api';
import { getCountries } from '../countries/api';
import { getDistricts } from '../districts/api';
import useGolfCourses from '../golfCourses/api';
import getGolfClubInfo from '../golfCourses/getGolfClubInfo';
import isGolfCourseNearCity, { CityType } from './isGolfCourseNearCity';

const useSearchData = () => {
  const [isAccommodationFilter] = useAccommodationFilter();
  const { data: allGolfCourses = [], isLoading: isLoadingGolfCourses } = useGolfCourses();
  const { data: allCities = [], isLoading: isLoadingCities } = useCities();
  const location = useLocation();

  return useMemo(() => {
    const golfCourses = isAccommodationFilter
      ? allGolfCourses.filter(golfCourse => !!getGolfClubInfo(golfCourse)?.accommodationId)
      : allGolfCourses;
    const countries = getCountries(golfCourses);
    const districts = getDistricts(golfCourses);
    const cities = isAccommodationFilter
      ? allCities.filter((city: CityType) =>
          golfCourses?.some(golfCourse => isGolfCourseNearCity(golfCourse, city))
        )
      : allCities;

    const [matchCountry] =
      matchRoutes([{ path: '/:locale/courses/countries/:country' }], location) || [];
    const [matchDistrict] =
      matchRoutes([{ path: '/:locale/courses/districts/:district' }], location) || [];
    const [matchCity] = matchRoutes([{ path: '/:locale/courses/cities/:city' }], location) || [];
    const [matchGolfCourse] =
      matchRoutes([{ path: '/:locale/booking/:clubId/:courseId' }], location) || [];

    const country = countries?.find(country => country.name === matchCountry?.params.country);
    const district = districts?.find(district => district.name === matchDistrict?.params.district);
    const city = cities.find(city => city.name === matchCity?.params.city);
    const golfCourse = golfCourses.find(
      golfCourse =>
        golfCourse.name ===
        `${matchGolfCourse?.params.clubId} - ${matchGolfCourse?.params.courseId}`
    );

    const findMatchingEntity = (pattern: string) => {
      if (!pattern) return null;

      const golfCourse = golfCourses.find(item => item.name === pattern);
      if (golfCourse) return golfCourse;

      const country = countries.find(item => item.name === pattern);
      if (country) return country;

      const district = districts.find(item => item.name === pattern);
      if (district) return district;

      const city = cities.find(
        item =>
          item.name.toLowerCase().includes(pattern) || item.search.toLowerCase().includes(pattern)
      );
      if (city) return city;

      return null;
    };

    return {
      value: golfCourse?.name || city?.name || district?.name || country?.name,
      allGolfCourses,
      golfCourses,
      countries,
      districts,
      cities,
      isLoading: isLoadingGolfCourses || isLoadingCities,
      findMatchingEntity,
    };
  }, [
    isAccommodationFilter,
    allGolfCourses,
    allCities,
    location,
    isLoadingGolfCourses,
    isLoadingCities,
  ]);
};

export default useSearchData;
