import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import classNames from '../../helpers/classNames';
import styles from './benefits.module.scss';
import { useBenefits } from './useBenefits';

const Benefits: FC = () => {
  const benefits = useBenefits();

  return (
    <div className="d-flex flex-column flex-lg-row justify-content-start justify-content-sm-between align-items-sm-start gap-2">
      {benefits.map(({ img, alt, text, url = '', tooltip = '' }, index) => (
        <div
          key={`item-${index + 1}`}
          className="d-flex align-items-center gap-4 flex-column flex-sm-row w-100"
        >
          {url ? (
            <Link to={url}>
              <img src={img} alt={alt} width={100} height={100} className="flex-shrink-0" />
            </Link>
          ) : (
            <img src={img} alt={alt} width={100} height={100} className="flex-shrink-0" />
          )}
          <div className={classNames('text-center text-sm-start', tooltip && styles.tooltip)}>
            {text}
            {tooltip && (
              <div className="tooltip bs-tooltip-bottom fade">
                <div className="arrow tooltip-arrow" style={{ left: 'calc(50% - 6px)' }} />
                <div className="tooltip-inner">{tooltip}</div>
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Benefits;
