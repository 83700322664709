import { useEffect, useState } from 'react';
import { Modal, ModalBody } from 'reactstrap';

import ForgotPasswordForm from '../../components/ForgotPasswordForm';
import GuestForm from '../../components/GuestForm/GuestForm';
import LoginForm from '../../components/LoginForm';
import RegisterForm from '../../components/RegisterForm';
import useStore from '../../store/useStore';

const VIEW_TYPES = {
  LOGIN: 'login',
  REGISTER: 'register',
  FORGOT_PASSWORD: 'forgot-password',
  GUEST: 'guest',
};

function AuthModal() {
  const [view, setView] = useState('login');

  const authModalOpen = useStore(state => state.authModalOpen);
  const openAuthModal = useStore(state => state.openAuthModal);
  const setGuest = useStore(state => state.setGuest);

  useEffect(() => {
    document.body.classList.add('new-site');

    const cb = () => (authModalOpen ? openAuthModal(false) : null);
    window.addEventListener('beforeunload', cb);

    return () => {
      document.body.classList.remove('new-site');
      window.removeEventListener('beforeunload', cb);
    };
  }, [authModalOpen, openAuthModal]);

  const showRegister = () => setView(VIEW_TYPES.REGISTER);
  const showLogin = () => setView(VIEW_TYPES.LOGIN);
  const showForgotPassword = () => setView(VIEW_TYPES.FORGOT_PASSWORD);
  const showGuestForm = () => setView(VIEW_TYPES.GUEST);

  const guestFormSubmit = (guest: { email: string; firstName: string; lastName: string }) => {
    setGuest(guest);
    openAuthModal(false);
  };

  const renderComponent = () => {
    if (view === VIEW_TYPES.LOGIN) {
      return (
        <LoginForm
          showRegister={showRegister}
          showGuestForm={showGuestForm}
          showForgotPassword={showForgotPassword}
        />
      );
    } else if (view === VIEW_TYPES.REGISTER) {
      return <RegisterForm showLogin={showLogin} />;
    } else if (view === VIEW_TYPES.FORGOT_PASSWORD) {
      return <ForgotPasswordForm showLogin={showLogin} />;
    } else if (view === VIEW_TYPES.GUEST) {
      return <GuestForm onCancel={showLogin} onSubmit={guestFormSubmit} />;
    }
  };

  return (
    <Modal isOpen toggle={() => openAuthModal(false)} centered>
      <ModalBody className="p-0">{renderComponent()}</ModalBody>
    </Modal>
  );
}

export default AuthModal;
