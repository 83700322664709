import { useQuery } from 'react-query';

import { get } from '../../http';
import mapGolfCourses from './mapGolfCourses';
import { ActiveGolfClubsResponseType, GolfClubsInfoResponseType } from './types';

export const QUERY_KEY_GOLFCOURSES = 'golfCourses';

const fetchActiveGolfClubs = () => get<ActiveGolfClubsResponseType>('v2/GolfClubs/Active');
const fetchGolfClubsInfo = () => get<GolfClubsInfoResponseType>('v2/GolfClubs/GolfClubInfo');

export const getGolfCourses = async () => {
  const [activeGolfClubs, golfClubsInfo] = await Promise.all([
    fetchActiveGolfClubs(),
    fetchGolfClubsInfo(),
  ]);

  return activeGolfClubs && golfClubsInfo
    ? mapGolfCourses(activeGolfClubs, golfClubsInfo)
    : undefined;
};

const useGolfCourses = () => useQuery(QUERY_KEY_GOLFCOURSES, getGolfCourses);

export default useGolfCourses;
