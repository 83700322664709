import React, { FC, SVGProps } from 'react';

import styles from './Icons.module.scss';
import { ReactComponent as SVGArrowDown } from './arrowDown.svg';
import { ReactComponent as SVGCamper } from './camper.svg';
import { ReactComponent as SVGCheck } from './check.svg';
import { ReactComponent as SVGCoins } from './coins.svg';
import { ReactComponent as SVGDrop } from './drop.svg';
import { ReactComponent as SVGGolfClub } from './golfclub.svg';
import { ReactComponent as SVGGolfer } from './golfer.svg';
import { ReactComponent as SVGMinus } from './minus.svg';
import { ReactComponent as SVGPayPalLogo } from './paypal-logo.svg';
import { ReactComponent as SVGPhone } from './phone.svg';
import { ReactComponent as SVGPlug } from './plug.svg';
import { ReactComponent as SVGPlus } from './plus.svg';
import { ReactComponent as SVGTrash } from './trash.svg';
import { ReactComponent as SVGVoucher } from './voucher.svg';
import { ReactComponent as SVGWallet } from './wallet.svg';

interface IconProps extends SVGProps<SVGSVGElement> {
  size?: number;
}

function createIconComponent(Component: FC<SVGProps<SVGSVGElement>>): FC<IconProps> {
  return props => {
    const { size, style, ...rest } = props;

    return (
      <Component
        className={styles.icon}
        style={{
          ...style,
          ...(size ? { width: size, height: size } : {}),
        }}
        {...rest}
      />
    );
  };
}

export const IconCamper = createIconComponent(SVGCamper);

export const IconGolfer = createIconComponent(SVGGolfer);

export const IconGolfClub = createIconComponent(SVGGolfClub);

export const IconDrop = createIconComponent(SVGDrop);

export const IconPlug = createIconComponent(SVGPlug);

export const IconPlus = createIconComponent(SVGPlus);

export const IconMinus = createIconComponent(SVGMinus);

export const IconTrash = createIconComponent(SVGTrash);

export const IconCheck = createIconComponent(SVGCheck);

export const IconCoins = createIconComponent(SVGCoins);

export const IconPhone = createIconComponent(SVGPhone);

export const IconWallet = createIconComponent(SVGWallet);

export const IconPayPalLogo = createIconComponent(SVGPayPalLogo);

export const IconVoucher = createIconComponent(SVGVoucher);

export const IconArrowDown = createIconComponent(SVGArrowDown);
