import costaDelSol from '../assets/img/teasers/costa_del_sol.png';
import costaDelSolWebp from '../assets/img/teasers/costa_del_sol.webp';
import costaBlanca from '../assets/img/teasers/costa_blanca.png';
import costaBlancaWebp from '../assets/img/teasers/costa_blanca.webp';
import palmaDeMallorca from '../assets/img/teasers/palma_de_mallorca.png';
import palmaDeMallorcaWebp from '../assets/img/teasers/palma_de_mallorca.webp';
import barceona from '../assets/img/teasers/barcelona.png';
import barceonaWebp from '../assets/img/teasers/barcelona.webp';
import praha from '../assets/img/teasers/prag.png';
import prahaWebp from '../assets/img/teasers/prag.webp';
import slovakia from '../assets/img/teasers/slovakien.png';
import slovakiaWebp from '../assets/img/teasers/slovakien.webp';
import northSpain from '../assets/img/teasers/norra_spanien.png';
import northSpainWebp from '../assets/img/teasers/norra_spanien.webp';

const destinationSuggestions = {
  popular: [
    {
      id: 125,
      name: 'Costa del Sol-Cádiz & Huelva',
      type: 'districts',
      images: { image: costaDelSol, webp: costaDelSolWebp }
    },
    {
      id: 126,
      name: 'Costa-Blanca, Murcia & Almeria',
      type: 'districts',
      images: { image: costaBlanca, webp: costaBlancaWebp }
    },
    {
      id: 127,
      name: 'Mallorca & Menorca',
      type: 'districts',
      images: { image: palmaDeMallorca, webp: palmaDeMallorcaWebp }
    },
    {
      id: 124,
      name: 'Barcelona & Costa Brava',
      type: 'districts',
      images: { image: barceona, webp: barceonaWebp }
    }
  ],
  discoverNew: [
    {
      id: 14,
      name: 'Praha',
      type: 'districts',
      image: praha,
      images: { image: praha, webp: prahaWebp }
    },
    {
      id: 19,
      name: 'Slovakien',
      type: 'country',
      image: slovakia,
      images: { image: slovakia, webp: slovakiaWebp }
    },
    {
      id: 129,
      name: 'Centrala & Norra Spanien',
      type: 'districts',
      image: northSpain,
      images: { image: northSpain, webp: northSpainWebp }
    }
  ]
};

export default destinationSuggestions;
