import { post } from '../../http';
import { RegisterInputType, RegisterPayloadType, RegisterResponseType } from './register.types';

const register = (data: RegisterInputType) => {
  const payload: RegisterPayloadType = {
    firstName: data.firstName,
    lastName: data.lastName,
    email: data.email,
    password: data.password,
    confirmPassword: data.confirmPassword,
    // TODO Does a telNumber field supported in API?
    // telNumber: data.phone,
    golfId: data.golfId,
    // TODO Does a gender field supported in API?
    // gender: data.gender,
    memberIn: data.memberIn || '',
    countryId: data.countryId || '',
    currencyId: data.currencyId,
    ghCardNr: data.ghCardNr,
    origin: 'website',
  };

  // TODO: The best practice is to transmit user registration data through the request body (payload),
  //  especially if it is done via the POST method. Transmitting data through the request body allows
  //  for a higher level of security and data confidentiality.

  return post<RegisterResponseType>(`Account/Register`, {}, { ...payload });
};

export default register;
