import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';

import useTranslate from '../hooks/useTranslate';

function LangSelect({ flags }) {
  const location = useLocation();
  const { activeLanguage, languages } = useTranslate();

  return (
    activeLanguage &&
    languages && (
      <>
        <UncontrolledDropdown nav inNavbar>
          <DropdownToggle to={location} nav caret>
            <img
              width="30"
              style={{ objectFit: 'cover' }}
              src={flags[activeLanguage.code]}
              alt="current-language"
            />
          </DropdownToggle>
          <DropdownMenu className="p-3" style={{ minWidth: '100px' }}>
            {languages.map(lang => (
              <DropdownItem
                key={lang.code}
                tag={Link}
                to={{
                  ...location,
                  pathname: `/${lang.code}/${location.pathname.replace(
                    new RegExp(`^/${activeLanguage.code}/`),
                    ''
                  )}`,
                }}
                hrefLang={lang.code}
                onClick={e => {
                  window.location.href = e.currentTarget.href;
                }}
                className="d-flex flex-column align-content-center justify-content-center"
              >
                <img width="40" src={flags[lang.code]} alt={lang.name} />
              </DropdownItem>
            ))}
          </DropdownMenu>
        </UncontrolledDropdown>
      </>
    )
  );
}

export default LangSelect;
