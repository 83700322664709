import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import HomePage from '../../pages/home/HomePage';
import {
  About,
  App,
  Booking,
  BookingAccommodation,
  BookingCanceled,
  BookingConfirm,
  BookingConfirmed,
  BookingGM,
  BookingPay,
  BookingUpdateConfirmation,
  Bookings,
  Camping,
  CancellationFeedback,
  Cities,
  City,
  Club,
  Company,
  Cookies,
  Countries,
  Country,
  Courses,
  Destination,
  Destinations,
  District,
  Districts,
  Favorites,
  Feedback,
  ForgotPassword,
  Friends,
  GiftCard,
  GolfClubBookingResponse,
  Guaranteed,
  Guide,
  GuideCategory,
  GuideVideo,
  Help,
  HomePageAccommodation,
  Imprint,
  Inspiration,
  Inspirations, // InviteUser,
  InviteClub,
  Landing,
  News,
  NotFound,
  Popular,
  PrivacyPolicy,
  Profile,
  QuizGame,
  Register,
  RegisterClub,
  ResetPassword,
  SignIn,
  SignOut,
  TermsPayment,
  TermsUser,
  TravelPlanner,
  Vouchers,
  Welcome,
} from '../../routes';
import useStore from '../../store/useStore';

function AppRouter() {
  return (
    <Routes>
      <Route path="/de/impressum" exact element={<Imprint />} />
      <Route path="/:locale/*" element={<PublicPages />} />
      <Route
        path="/:locale/profile/*"
        element={
          <RequireAuth redirectTo="signin/">
            <ProtectedPages />
          </RequireAuth>
        }
      />

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

function PublicPages() {
  return (
    <div className="page-container">
      <Routes>
        <Route index element={<HomePage />} />
        <Route path="book-accommodation" element={<HomePageAccommodation />} />
        <Route path="booking">
          <Route index element={<Booking />} />
          <Route path="golfhaftet" element={<BookingGM />} />
          <Route path=":clubId/:courseId" element={<Booking />} />
          <Route path="confirm" element={<BookingConfirm />} />
          <Route path="pay" element={<BookingPay />} />
          <Route path="confirmed" element={<BookingConfirmed />} />
          <Route path="canceled" element={<BookingCanceled />} />
          <Route path="accommodation">
            <Route index element={<BookingAccommodation />} />
            <Route path=":accommodationId" element={<BookingAccommodation />} />
          </Route>
          <Route path="updateresponse" element={<BookingUpdateConfirmation />} />
        </Route>
        <Route path="accommodation" element={<BookingAccommodation />} />
        <Route path="courses">
          <Route index element={<Courses />} />
          <Route path="countries" element={<Countries />} />
          <Route path="countries/:country" element={<Country />} />
          <Route path="districts" element={<Districts />} />
          <Route path="districts/:district" element={<District />} />
          <Route path="cities" element={<Cities />} />
          <Route path="cities/:city" element={<City />} />
        </Route>
        <Route path="register">
          <Route index element={<Register />} />
          <Route path="welcome" element={<Welcome />} />
          <Route path="club" element={<RegisterClub />} />
        </Route>
        <Route path="signin">
          <Route index element={<SignIn />} />
          <Route path="forgot" element={<ForgotPassword />} />
          <Route path="reset" element={<ResetPassword />} />
        </Route>
        <Route path="signout">
          <Route index element={<SignOut />} />
        </Route>
        <Route path="invite">
          {/* <Route path="friend" element={<InviteUser />} /> */}
          <Route path="club" element={<InviteClub />} />
        </Route>
        <Route path="guide">
          <Route index element={<Guide />} />
          <Route path=":category" element={<GuideCategory />} />
          <Route path=":category/:videoId" element={<GuideVideo />} />
        </Route>
        <Route path="terms">
          <Route path="payment" element={<TermsPayment />} />
          <Route path="user" element={<TermsUser />} />
          <Route path="privacy" element={<PrivacyPolicy />} />
          <Route path="cookies" exact element={<Cookies />} />
        </Route>
        <Route path="popular" element={<Popular />} />
        <Route path="guaranteed" element={<Guaranteed />} />
        <Route path="about" element={<About />} />
        <Route path="company" element={<Company />} />
        <Route path="help/:groupId?/:queryId?" element={<Help />} />
        <Route path="feedback" element={<Feedback />} />
        <Route path="app" element={<App />} />
        <Route path="golfclub/bookingresponse" element={<GolfClubBookingResponse />} />
        <Route path="feedbackCancellation" element={<CancellationFeedback />} />
        <Route path="landing/*" element={<Landing />} />
        <Route path="camping" element={<Camping />} />
        <Route path="news" element={<News />} />
        <Route path="inspirations/:releaseDate/:normalizedTitle" element={<Inspiration />} />
        <Route path="inspirations" exact element={<Inspirations />} />
        <Route path="quizgame" exact element={<QuizGame />} />
        <Route path="club19" exact element={<Club />} />
        <Route path="giftcard" exact element={<GiftCard />} />
        <Route path="destinations/:normalizedName" element={<Destination />} />
        <Route path="destinations" exact element={<Destinations />} />
        <Route path="planmytrip" exact element={<TravelPlanner />} />
        <Route path="*" element={<Navigate to="../" replace={true} />} />
      </Routes>
    </div>
  );
}

function ProtectedPages() {
  return (
    <Routes>
      <Route index element={<Profile />} />
      <Route path="me" element={<Profile />} />
      <Route path="friends" element={<Friends />} />
      <Route path="bookings" element={<Bookings />} />
      <Route path="favorites" element={<Favorites />} />
      <Route path="vouchers" element={<Vouchers />} />
    </Routes>
  );
}

function RequireAuth({ children, redirectTo }) {
  const isLoggedIn = useStore(state => state.isLoggedIn);
  const languageCode = useStore(state => state.language);
  return isLoggedIn ? children : <Navigate to={`/${languageCode}/${redirectTo}`} />;
}

export default AppRouter;
