

const textDefault = {
    marginBottom: "1rem"
}
export const textStyles = {
    default: textDefault,
    quotes: {
        ...textDefault,
        fontWeight: 'lighter',
        marginBottom: "1rem", 
    },
    bold: {
        ...textDefault,
        fontWeight: 'bold',
        marginBottom: "1rem", 
    },
    footer: {
        ...textDefault,
        fontWeight: 'lighter',
    }
}


const titleDefault = {
    fontFamily: 'Montserrat, Open Sans, sans-serif',
    color: '#283646',
    fontWeight: '500',
    marginBottom: '1rem'
}

export const titleStyles = {
    footerLink: {
        ...titleDefault,   
        color: '#000',
        fontWeight: '600'
    }
}


const Styles = {
    texts: textStyles,
    titles: titleStyles
}

export default Styles;