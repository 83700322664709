import useStore from '../store/useStore';

export const supportedLang = lang => lang && ['sv', 'en', 'dk', 'de', 'no', 'es'].includes(lang);

export const getLangFromBrowser = () => {
  let userLang =
    (navigator && navigator.language) || (navigator && navigator.userLanguage) || false;
  userLang = (userLang && userLang.includes('-') && userLang.substr(0, 2)) || userLang;
  return (supportedLang(userLang) && userLang) || 'en';
};

export const getLang = pathname => {
  const langCode = checkUrlForLang(pathname);
  let lang = supportedLang(langCode) ? langCode : getLangFromBrowser();
  lang = lang === 'es' ? 'en' : lang;
  return lang;
};

export const checkUrlForLang = pathname => {
  const langCode = pathname.split('/')[1];
  return !!langCode.match(/^([a-z]{2})$/) && langCode;
};

export const newPathname = (pathname, lang) => {
  const pathnameLangCode = checkUrlForLang(pathname);
  if (lang === 'es') {
    lang = 'en';
  }
  const langCode =
    (supportedLang(pathnameLangCode) && pathnameLangCode) || (supportedLang(lang) && lang);
  return (
    (!supportedLang(pathnameLangCode) &&
      `${langCode}${pathname
        .split('/')
        .filter(r => r !== pathnameLangCode)
        .join('/')}`) ||
    pathname
  );
};

export const language = getLang(window.location.pathname);
useStore.setState({ language });

export default getLang;
