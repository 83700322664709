import React, { FC, useEffect, useMemo, useState } from 'react';
import { Button, Toast, ToastBody } from 'reactstrap';

import { isAfter, parseISO, startOfDay, subWeeks } from 'date-fns';

import { isCurrentDateTimeWeekend } from '../../helpers/datetime';
import { formatISODate } from '../../helpers/formatISODate';
import useTranslate from '../../hooks/useTranslate';
import { useBookings } from '../../services/apis/booking/api';
import useStore from '../../store/useStore';

const PHONE_NUMBER = '+46 (0)8-120 050 15';
const TIMEOUT = 5 * 60 * 1000; // 5 minutes in ms

const isWeekend = isCurrentDateTimeWeekend();

const NeedHelpToast: FC = () => {
  const { translate } = useTranslate();

  const { needHelpToastClosedDate, setNeedHelpToastClosed, isLoggedIn } = useStore(state => state);
  const [isEnabled, setIsEnabled] = useState(false);
  const { refetch } = useBookings({ enabled: false });

  const needHelpToastClosed = useMemo(() => {
    return needHelpToastClosedDate === ''
      ? false
      : isAfter(parseISO(needHelpToastClosedDate), startOfDay(subWeeks(new Date(), 2)));
  }, [needHelpToastClosedDate]);

  const isOpened = !needHelpToastClosed && isEnabled;

  useEffect(() => {
    if (needHelpToastClosed) return;

    const timeoutId = window.setTimeout(async () => {
      if (isLoggedIn) {
        const { data: allUserBookings } = await refetch();
        const hasPaymentsLastWeek = !!allUserBookings?.upcoming?.some(
          (booking: any) =>
            booking?.payments?.[0]?.paymentDate &&
            isAfter(
              startOfDay(subWeeks(new Date(), 1)),
              parseISO(booking?.payments?.[0]?.paymentDate)
            )
        );

        setIsEnabled(!hasPaymentsLastWeek);
      } else {
        setIsEnabled(true);
      }
    }, TIMEOUT);

    return () => window.clearTimeout(timeoutId);
  }, [refetch, needHelpToastClosed, isLoggedIn]);

  return (
    <Toast
      isOpen={isOpened}
      className="align-self-end"
      style={{ backgroundColor: 'rgba(255, 255, 255, 1)', zIndex: 11 }}
    >
      <ToastBody>
        <div className="d-flex gap-2">
          <div>
            {translate('needHelpToast.text', { phoneNumber: PHONE_NUMBER })}
            {isWeekend && <p>{translate('needHelpToast.weekend-info')}</p>}
          </div>
          <div className="ms-auto">
            <Button
              className="btn-close"
              onClick={() => setNeedHelpToastClosed(formatISODate(new Date()))}
            ></Button>
          </div>
        </div>

        <Button color="success" tag="a" href={`tel:${PHONE_NUMBER}`} className="px-4 mt-2 btn-sm">
          {translate('needHelpToast.ctaCall')}
        </Button>
      </ToastBody>
    </Toast>
  );
};

export default NeedHelpToast;
