import { MappedGolfCourseType } from '../services/apis/golfCourses/types';
import { Slice } from './types';

export interface CreateSearchSlice {
  golfCourse: MappedGolfCourseType | null;
  setGolfCourse: (golfCourse: MappedGolfCourseType) => void;

  clearData: () => void;

  searchPhrases: string[];
  setSearchPhrase: (phrase: string) => void;
  clearSearchPhrases: () => void;
}

const createSearchSlice: Slice<CreateSearchSlice> = set => ({
  golfCourse: null,
  setGolfCourse: golfCourse =>
    set(() => ({
      golfCourse,
    })),
  clearData: () =>
    set(() => ({
      golfCourse: null,
    })),
  searchPhrases: [],
  setSearchPhrase: phrase =>
    set(state => ({
      searchPhrases: state.searchPhrases.includes(phrase)
        ? state.searchPhrases
        : [...state.searchPhrases, phrase],
    })),
  clearSearchPhrases: () => set(() => ({ searchPhrases: [] })),
});

export default createSearchSlice;
