import React, { FC, ReactNode } from 'react';
import { Translate } from 'react-localize-redux';
import { Button } from 'reactstrap';

import classNames from '../../helpers/classNames';
import { IconCamper, IconGolfer } from '../Icons/Icons';
import { SelectedOptionType } from '../SearchBarSwitcher/SearchBarSwitcher';
import styles from './SeerchBarTabs.module.scss';

interface SearchBarTabsProps {
  selectedOption?: SelectedOptionType;
  onChange?: (value: SelectedOptionType) => void;
}

const SearchBarTabs: FC<SearchBarTabsProps> = props => {
  const { selectedOption, onChange } = props;

  return (
    <div className="d-flex flex-row flex-wrap" style={{ gap: 6 }}>
      {(
        [
          {
            label: <Translate id="SearchBarSwitcherLabel.TeeTimes" />,
            icon: <IconGolfer size={26} />,
            value: 'tee_times',
          },
          {
            label: <Translate id="SearchBarSwitcherLabel.Accommodation" />,
            icon: <IconCamper size={26} />,
            value: 'accommodation',
          },
        ] as {
          label: ReactNode;
          icon: ReactNode;
          value: SelectedOptionType;
        }[]
      ).map(({ label, icon, value }) => (
        <Button
          className={classNames(styles.tab, selectedOption === value && styles.active)}
          key={value}
          onClick={() => onChange?.(value)}
        >
          {icon}
          <span className={styles.label}>{label}</span>
        </Button>
      ))}
    </div>
  );
};

export default SearchBarTabs;
