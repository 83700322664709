import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Container } from 'reactstrap';

import useTranslate from '../../hooks/useTranslate';
import { MappedGolfCourseType } from '../../services/apis/golfCourses/types';
import GolfCourseCard from '../GolfCourseCard/GolfCourseCard';
import ScrollSnapSlider from '../ScrollSnapSlider/ScrollSnapSlider';
import styles from './GolfCamping.module.scss';

interface GolfCampingProps {
  courses: MappedGolfCourseType[];
}

const GolfCamping: FC<GolfCampingProps> = props => {
  const { courses } = props;
  const { translate, activeLanguage } = useTranslate();

  return (
    <div className={styles.wrapper}>
      <Container className={styles.container}>
        <div className={styles.title}>{translate('golfCamping.title')}</div>
        <div>
          <div className={styles.subtitle}>{translate('golfCamping.subtitle')}</div>
          <div className={styles.description}>{translate('golfCamping.description')}</div>
        </div>
        <ScrollSnapSlider
          footer={
            <Link className={styles.link} to={`/${activeLanguage.code}/camping/`}>
              {translate('golfCamping.showAll')}
            </Link>
          }
        >
          {courses.map(course => (
            <GolfCourseCard key={course.id} golfCourse={course} isAccommodation responsive />
          ))}
        </ScrollSnapSlider>
      </Container>
    </div>
  );
};

export default GolfCamping;
