import { Translate } from 'react-localize-redux';
import SimpleLineIcons from 'react-simple-line-icons';
import { Button } from 'reactstrap';

export const PlayersInFlight = ({
  players,
  playersInFlight,
  seatsInFlight,
  removePlayer,
  isGolfhaftetRequest,
  isGitBooking,
  disabled,
}) => {
  const onPlayerClick = async player => {
    // Only in Golfamore bookings is it possible to remove your self from the flight
    if (!isGolfhaftetRequest && player.isLoggedInProfile) {
      return;
    }

    removePlayer(player);
  };

  return (
    <>
      <p className="mt-3 mb-1">
        <Translate id="default.BookingPlayers" /> ({playersInFlight}/{seatsInFlight})
      </p>
      {players?.map(p => (
        <Button
          key={p.id || p.key}
          className="d-flex py-2 px-3 border mb-1 player selected"
          block
          color="link"
          onClick={() => onPlayerClick(p)}
          disabled={disabled}
        >
          <SimpleLineIcons size="medium" name="check" /> {p.name || p.email}{' '}
          {isGitBooking && p.golfId}
          {isGolfhaftetRequest && p.ghCardNr && ` - ${p.ghCardNr}`}
          {p.price && ` (${p.price})`}
          {/* {isValidating === p.id && <PulseLoader size={10} />} */}
          {/* {error && error.playerId === p.id && (
            <Alert color="danger" className="p-1">
              {error.message}
            </Alert>
          )} */}
        </Button>
      ))}
    </>
  );
};
