import React, { FC, HTMLAttributes, PropsWithChildren } from 'react';

import classNames from '../../helpers/classNames';
import useHidingHeader from '../../hooks/useHidingHeader';

const StickyHeader: FC<PropsWithChildren<HTMLAttributes<HTMLDivElement>>> = props => {
  const { children, className, ...rest } = props;
  const hidingHeader = useHidingHeader();

  return (
    <div ref={hidingHeader} className={classNames('sticky-top', className)} {...rest}>
      {children}
    </div>
  );
};

export default StickyHeader;
