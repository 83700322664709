import React, { FC, MouseEventHandler, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Alert } from 'reactstrap';

import { formatCurrency } from '../../helpers/formatCurrency';
import usePrevious from '../../hooks/usePrevious';
import useTranslate from '../../hooks/useTranslate';
import useStore from '../../store/useStore';
import Button from '../Button/Button';

interface VoucherBannerPropTypes {
  amount: number;
  currency: string;
}
const VouchersBanner: FC<VoucherBannerPropTypes> = props => {
  const { amount, currency } = props;
  const redirectAfterAuthRef = useRef(false);
  const {
    isLoggedIn,
    openAuthModal,
    authModalOpen,
    vouchersBannerClosed,
    setVouchersBannerClosed,
  } = useStore(state => state);
  const prevAuthModalOpen = usePrevious(authModalOpen);
  const prevIsLoggedIn = usePrevious(isLoggedIn);
  const { translate, activeLanguage } = useTranslate();
  const urlToVouchersPage = `${activeLanguage?.code}/profile/vouchers`;
  const navigate = useNavigate();

  useEffect(() => {
    if (!redirectAfterAuthRef.current) return;

    // closed modal && authorized user
    if (prevAuthModalOpen && !authModalOpen && !prevIsLoggedIn && isLoggedIn) {
      navigate(urlToVouchersPage);
      setVouchersBannerClosed();
      redirectAfterAuthRef.current = false;
    }
  }, [
    prevAuthModalOpen,
    authModalOpen,
    prevIsLoggedIn,
    isLoggedIn,
    navigate,
    urlToVouchersPage,
    setVouchersBannerClosed,
  ]);

  if (vouchersBannerClosed) return null;

  const handleClick: MouseEventHandler<HTMLButtonElement> = e => {
    if (isLoggedIn) {
      setVouchersBannerClosed();
    } else {
      e.preventDefault();
      redirectAfterAuthRef.current = true;
      openAuthModal(true);
    }
  };

  const formattedAmount = formatCurrency(currency, amount, activeLanguage?.code);

  return (
    <Alert
      isOpen={!vouchersBannerClosed}
      toggle={setVouchersBannerClosed}
      className="text-center d-flex flex-column flex-md-row align-items-center justify-content-center gap-2 gap-md-3 mb-0"
      style={{
        backgroundColor: '#7ca324',
        color: '#ffffff',
        borderRadius: 0,
      }}
    >
      <div className="fw-bold">
        {String(translate('vouchers.banner.description', { amount: formattedAmount }))}
      </div>
      <Button tag={Link} to={urlToVouchersPage} onClick={handleClick} className="btn-sm">
        {String(translate('vouchers.banner.action', { amount: formattedAmount }))}
      </Button>
    </Alert>
  );
};

export default VouchersBanner;
