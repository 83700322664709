import React, { useEffect } from 'react';
import { withLocalize } from 'react-localize-redux';
import { renderToStaticMarkup } from 'react-dom/server';

import {
  languages,
  onMissingTranslation,
  enTranslation,
  svTranslation,
  noTranslation,
  dkTranslation,
  deTranslation
} from '../services/i18n';

function InitialLanguage({ initialize, addTranslationForLanguage, setActiveLanguage, language }) {
  useEffect(
    () => {
      initialize({
        languages,
        options: {
          renderToStaticMarkup,
          onMissingTranslation,
          defaultLanguage: 'en',
          // todo prevent unsafe html usage
          renderInnerHtml: true
        }
      });

      setActiveLanguage(language);

      addTranslationForLanguage(enTranslation, 'en');
      addTranslationForLanguage(svTranslation, 'sv');
      addTranslationForLanguage(noTranslation, 'no');
      addTranslationForLanguage(dkTranslation, 'dk');
      addTranslationForLanguage(deTranslation, 'de');
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return null;
}

export default React.memo(withLocalize(InitialLanguage));
