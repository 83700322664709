import * as clubs from './clubs';
import * as countries from './countries';
import * as auth from './auth';
import * as payment from './payment';
import * as profile from './profile';
import * as feedback from './feedback';
import * as order from './order';
import * as invite from './invite';
import * as sharedResources from './sharedResources';
import * as golfClubResponse from './golfClubResponse';

const ontee = {
  clubs,
  countries,
  auth,
  payment,
  profile,
  feedback,
  order,
  invite,
  sharedResources,
  golfClubResponse
};

export default ontee;
