import { post, get, del } from '../http';

// new order
export const newOrder = data => post(`v2/Orders/Items`, data);
// del order
export const cancelOrder = orderID => del(`v2/Orders/${orderID}`);

// add item to order
export const addItem = (orderID, data) => post(`v2/Orders/${orderID}/Items`, data);

// add item to order
export const cancelItem = (orderID, itemID) => del(`v2/Orders/${orderID}/Items/${itemID}`);

export const complete = (orderID, data) => post(`v2/Orders/${orderID}/Confirm`, data);

export const fetch = orderID => get(`v2/Orders/${orderID}`);

export const addUsers = (orderID, itemID, data) =>
  post(`v2/Orders/${orderID}/Items/${itemID}/Users`, data);

export const newEmptyPackageOrder = id => post('v2/Orders', null, { packageId: id });

export const getOrders = async () => {
  const orders = await get('v2/orders/me');

  if (orders && orders.length > 0) {
    orders.forEach(order => {
      order.orderItems.forEach(item => {
        const { booking } = item;
        if (booking) {
          item.tinyBooking = {
            id: booking.id,
            profileId: booking.profileId,
            orderRef: booking.orderRef,
            bookingStatus: booking.status,
            // golfClubId: booking.golfClub.id,
            golfClub: booking.golfClub,
            // golfCourseId: booking.golfCourse.id,
            golfCourse: booking.golfCourse,
            leftToPay: booking.leftToPay,
            orderPrice: booking.orderPrice,
            orderPriceVAT: booking.orderPriceVAT,
            nrPlayers: booking.nrPlayers,
            currency: booking.currency,
            startTime: booking.startTime,
            campaign: booking.campaign,
            userBookings: booking.userBookings.map(({ user }) => ({
              id: user.id,
              name: `${user.firstName} ${user.lastName}`,
              price: user.price
            }))
          };
        }
      });
    });
  }
  return Promise.resolve(orders);
};
