import { FC, FormEventHandler, useCallback, useState } from 'react';
import {
  Alert,
  Button as ButtonBase,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
} from 'reactstrap';

import onTeeLogo from '../../assets/img/logotype.png';
import classNames from '../../helpers/classNames';
import useBreakpoints from '../../hooks/useBreakpoints';
import useTranslate from '../../hooks/useTranslate';
import { PrivacyPolicyContent } from '../../pages/privacyPolicy';
import { useSubscribeToNewsletter } from '../../services/apis/newsletterSubscription/api';
import useStore from '../../store/useStore';
import Button from '../Button/Button';
import { ExternalLinkWithModal } from '../ExternalLinkWithModal';
import styles from './NewsletterSubscriptionPopup.module.scss';

interface NewsletterSubscriptionPopupProps {
  onClose: () => void;
}

export const NewsletterSubscriptionPopup: FC<NewsletterSubscriptionPopupProps> = props => {
  const { onClose } = props;
  const { translate, activeLanguage } = useTranslate();
  const isGolfhaftetRequest = useStore(state => state.isGolfhaftetRequest);
  const [email, setEmail] = useState('');
  const [acceptPolicy, setAcceptPolicy] = useState(false);
  const { mutate: subscribe, isLoading, error, isSuccess } = useSubscribeToNewsletter();
  const isBreakPoints = useBreakpoints();

  const handleSubmit = useCallback<FormEventHandler<HTMLFormElement>>(
    e => {
      e.preventDefault();
      subscribe(email);
    },
    [email, subscribe]
  );

  return (
    <Modal isOpen fade={false} toggle={onClose} size="lg" className="border-0" centered>
      <ModalBody className="p-0 position-relative">
        <ButtonBase onClick={onClose} className="btn-close position-absolute top-0 end-0 m-3" />
        <div className="d-flex">
          <div
            className={classNames('p-4 p-md-5 text-center', isBreakPoints.md ? 'w-50' : 'w-100')}
          >
            <img src={onTeeLogo} height="50" className="mb-4" alt="OnTee logo" />

            {isSuccess ? (
              <>
                <Alert color="success" className="mb-4">
                  {translate('newsletterPopup.successMessage')}
                </Alert>
                <Button primary onClick={onClose} className="w-100 justify-content-center">
                  {translate('default.close')}
                </Button>
              </>
            ) : (
              <>
                <h3 className="fw-bold mb-3">{translate('newsletterPopup.title')}</h3>
                <p className="text-muted mb-4">{translate('newsletterPopup.description')}</p>

                <Form onSubmit={handleSubmit}>
                  <FormGroup>
                    <Input
                      type="email"
                      placeholder={String(translate('newsletterPopup.emailPlaceholder'))}
                      value={email}
                      onChange={e => setEmail(e.target.value)}
                      required
                    />
                  </FormGroup>

                  <FormGroup check>
                    <Input
                      type="checkbox"
                      id="acceptPolicy"
                      checked={acceptPolicy}
                      onChange={e => setAcceptPolicy(e.target.checked)}
                      required
                      className="flex-shrink-0"
                    />
                    <Label check for="acceptPolicy" className="text-muted text-start m-0">
                      {translate('newsletterPopup.policyText', {
                        linkToPrivacyPolicy: (
                          <ExternalLinkWithModal
                            href={`/${activeLanguage.code}/terms/privacy/`}
                            component={PrivacyPolicyContent}
                            isGolfhaftetRequest={isGolfhaftetRequest}
                          >
                            {translate('newsletterPopup.privacyPolicyLabel')}
                          </ExternalLinkWithModal>
                        ),
                      })}
                    </Label>
                  </FormGroup>

                  {!!error && (
                    <Alert color="danger" className="mt-3">
                      {String(error)}
                    </Alert>
                  )}

                  <Button
                    type="submit"
                    primary
                    loading={isLoading}
                    disabled={isLoading}
                    className="w-100 justify-content-center mt-3"
                  >
                    {translate('newsletterPopup.submitButton')}
                  </Button>
                </Form>
              </>
            )}
          </div>

          <div
            className={classNames(
              'w-50 rounded-end',
              styles.backgroundImage,
              isBreakPoints.md ? 'd-block' : 'd-none'
            )}
          />
        </div>
      </ModalBody>
    </Modal>
  );
};
