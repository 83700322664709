import { isValidEmail, isValidGolfId } from '../../../helpers/regex';
import validateCard from '../../../services/apis/validateCard';

const cardValidationErrors = {
  'visits.err-card-blocked': 'default.CartValidationErrors.CardBlocked',
  'visits.err-card-nexist': 'default.CartValidationErrors.CardDoesntExist',
  'visits.err-visit-already': 'default.CartValidationErrors.CardVisitAlready',
  'visits.err-card-nactive': 'default.CartValidationErrors.CardInactive',
  'visits.err-card-nactive2': 'default.CartValidationErrors.CardInactive',
  'visits.err-card-year': 'default.CartValidationErrors.CardInvalidYear',
  'visits.err-play-not-valid-date': 'default.CartValidationErrors.ClubNotAvailableAtDate',
};

export const validateCartItemsAndPlayers = (cartItems, isThirdParty, gitBookingExist) =>
  cartItems.map(ci => {
    return {
      ...ci,
      valid: isValidCartItem(ci, isThirdParty, gitBookingExist),
    };
  });

const isValidCartItem = (ci, isThirdParty, gitBookingExist) => {
  const isGitBooking = gitBookingExist && ci.type === 'booking';
  if (isGitBooking && !ci.playersValidated) {
    return false;
  }

  const selectedNrOfPlayers = ci.users?.length;
  return (
    ci.type !== 'booking' ||
    !isPlayersRequired(ci, isThirdParty) ||
    selectedNrOfPlayers === ci.amount
  );
};

export const bookerInAllFlights = (cartItems, profile) => {
  const bookedFlights = cartItems.length;
  const bookerInFlights = cartItems.filter(ci => ci.users?.find(u => u.id === profile.id)).length;
  return bookedFlights === bookerInFlights;
};

// TODO: Handle better
export const isPlayersRequired = (ci, isThirdParty) =>
  ci.currency === 'SEK' || ci.fromGolfhaftet || isThirdParty;

export const validateCardNumberFormat = email =>
  !/^\d{8}$/.test(email) ? 'default.CartValidationErrors.WrongCardNumber' : null;

export const isCardNrAlreadyAdded = (players, email) =>
  players.some(player => player.ghCardNr === email)
    ? 'default.CartValidationErrors.CardAdded'
    : null;

export const validateCardNumber = async (ghCardNr, golfCourseId, startTime) => {
  try {
    const body = {
      ghCardNr,
      golfCourseId,
      startTime,
      email: '',
    };
    const error = await validateCard(body);
    if (error?.errorCode || error?.internalMessage) {
      return (
        cardValidationErrors[error.errorCode] ||
        cardValidationErrors[error.internalMessage] ||
        'default.CartValidationErrors.InternalError'
      );
    }
  } catch (error) {
    return 'default.CartValidationErrors.InternalError';
  }

  return null;
};

export const validateEmail = email =>
  !isValidEmail(email) ? 'default.CartValidationErrors.EmptyPlayerEmailId' : null;
export const isEmailAlreadyAdded = (players, email) =>
  players.some(player => player.email === email)
    ? 'default.CartValidationErrors.EmailExists'
    : null;

export const validateGolfId = email =>
  !isValidGolfId(email) ? 'default.CartValidationErrors.EmptyPlayerGolfId' : null;
export const isGolfIdAlreadyAdded = (players, golfId) =>
  players.some(player => player.golfId === golfId)
    ? 'default.CartValidationErrors.GolfIdExists'
    : null;
