import React from 'react';

function FlexCenterComponent({ className, style, children }) {
  return <div
    className={`FlexCenter d-flex flex-column justify-content-center align-items-center ${className}`}
    style={{ width: '100%', height: '100%', flexGrow: 1, ...style }}
  >
    {children}
  </div>
}

export default FlexCenterComponent;
