import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import useTranslate from '../../hooks/useTranslate';
import getInspirationUrl from '../../services/apis/inspirations/getInspirationUrl';
import { InspirationType } from '../../services/apis/inspirations/types';
import LazyImg from '../LazyImg/LazyImg';
import styles from './InspirationCard.module.scss';

interface InspirationCardProps {
  inspiration: InspirationType;
}

const InspirationCard: FC<InspirationCardProps> = props => {
  const { inspiration } = props;
  const { translate, activeLanguage } = useTranslate();

  return (
    <Link
      to={`/${activeLanguage.code}/inspirations/${getInspirationUrl(inspiration)}`}
      className={styles.container}
    >
      <div className={styles.imageWrapper}>
        <LazyImg
          className={styles.image}
          src={inspiration.imageUrl ?? ''}
          alt={inspiration.title}
        />
      </div>
      <div className={styles.info}>
        <div>{String(translate(`Inspirations.categories.${inspiration.category}`))}</div>
        {inspiration.readTime > 0 && (
          <div className={styles.readingTime}>
            {translate('inspiration.minRead', { min: inspiration.readTime })}
          </div>
        )}
      </div>
      <div className={styles.title}>{inspiration.title}</div>
    </Link>
  );
};

export default InspirationCard;
