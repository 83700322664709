import React from 'react';
import { Button, CardImg } from 'reactstrap';

function TeaserItem({ title, onClick, images }) {
  return (
    <Button color="outline" className="p-0" onClick={onClick} style={{ position: 'relative' }}>
      <CardImg width="100%" alt="" tag="div">
        <picture>
          <source type="image/webp" srcSet={images.webp} />
          <img
            src={images.image}
            alt={title}
            className="img-fluid"
            style={{ borderRadius: '5px', maxHeight: '175px', minHeight: '175px' }}
          />
        </picture>
      </CardImg>
      <div style={{ position: 'absolute', left: '5px', top: '50%' }}>
        <h4 className="text-white fw-bold">{title}</h4>
      </div>
    </Button>
  );
}

export default TeaserItem;
