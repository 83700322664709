import { useMutation, useQuery, useQueryClient } from 'react-query';

import useStore from '../../../store/useStore';
import { del, get, post } from '../../http';
import { FavouriteCourseResponseType, FavouriteCourseType } from './types';

const KEY = ['profile', 'favoriteCourses'];

const getFavouriteCourses = () => get<FavouriteCourseResponseType>('FavoritegolfCourses/Me');

const createFavouriteCourse = (courseId: FavouriteCourseType['GolfCourseId']) =>
  post<FavouriteCourseType>(`FavoritegolfCourses/Me/${courseId}`, {});

const deleteFavouriteCourse = (courseId: FavouriteCourseType['GolfCourseId']) =>
  del(`FavoritegolfCourses/Me/${courseId}`);

export const useFavouriteCourses = () => {
  const { isLoggedIn } = useStore.getState();

  return useQuery(KEY, getFavouriteCourses, { enabled: isLoggedIn });
};

export const useDeleteFavouriteCourse = () => {
  const queryClient = useQueryClient();

  return useMutation(deleteFavouriteCourse, {
    onSuccess: (_, courseId) => {
      queryClient.setQueryData<FavouriteCourseType[]>(KEY, favourites =>
        (favourites ?? []).filter(item => item.golfCourse.id !== courseId)
      );
    }
  });
};

export const useCreateFavoriteCourse = () => {
  const queryClient = useQueryClient();

  return useMutation(createFavouriteCourse, {
    onSuccess: data => {
      queryClient.setQueryData<FavouriteCourseType[]>(KEY, favourites => {
        const result = favourites ?? [];

        if (data) {
          result.push(data);
        }

        return result;
      });
    }
  });
};
