import { QueryClient } from 'react-query';
import { createWebStoragePersistor } from 'react-query/createWebStoragePersistor-experimental';
import { persistQueryClient } from 'react-query/persistQueryClient-experimental';

import { QUERY_KEY_CITIES, getCities } from './apis/cities/api';
import { QUERY_KEY_GOLFCOURSES, getGolfCourses } from './apis/golfCourses/api';
import { QUERY_KEY_STATISTICS } from './apis/statistics';
import { QUERY_KEY_TEETIMES } from './apis/teeTimes/api';

const oneMinute = 1000 * 60;
const oneHour = oneMinute * 60;
const halfDay = oneHour * 12;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // Reduce re-rendering
      // If set to "tracked", access to properties will be tracked, and the component will only re-render when one of the tracked properties change.
      // https://react-query.tanstack.com/reference/useQuery#notifyOnChangeProps
      notifyOnChangeProps: 'tracked',
      staleTime: oneMinute * 5,
      // https://react-query.tanstack.com/plugins/persistQueryClient#how-does-it-work
      cacheTime: halfDay,
      maxAge: halfDay,
      // todo maybe remove this
      retry: 1,
    },
  },
});

queryClient.setQueryDefaults(QUERY_KEY_GOLFCOURSES, { staleTime: halfDay });
queryClient.setQueryDefaults(QUERY_KEY_STATISTICS, { staleTime: halfDay });
queryClient.setQueryDefaults(QUERY_KEY_CITIES, { staleTime: halfDay });
queryClient.setQueryDefaults(QUERY_KEY_TEETIMES, {
  staleTime: oneMinute,
  cacheTime: oneMinute * 5,
  retry: 0,
});

const localStoragePersistor = createWebStoragePersistor({
  storage: window.localStorage,
  key: 'ontee',
});

persistQueryClient({
  queryClient,
  persistor: localStoragePersistor,
});

export const prefetchAppData = async language => {
  const prevLanguage = queryClient.getQueryData('language');
  if (prevLanguage !== language) {
    queryClient.clear();
    await queryClient.setQueryData('language', language);
  }
  // Use prefetchQuery over setQueryData to prevent multiple fetch.
  await queryClient.prefetchQuery(QUERY_KEY_GOLFCOURSES, getGolfCourses);
  await queryClient.prefetchQuery(QUERY_KEY_CITIES, getCities);
};

export default queryClient;
