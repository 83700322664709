import { uniqBy } from 'lodash-es';

import { latinizeReplace } from '../../language/latinize';
import useGolfCourses from '../golfCourses/api';
import { MappedGolfCourseType } from '../golfCourses/types';
import { MappedCountryType } from './types';

export const getCountries = (golfCourses?: MappedGolfCourseType[]): MappedCountryType[] => {
  if (!golfCourses) return [];

  return uniqBy(
    golfCourses.map(({ country }) => ({
      ...country,
      search: country.name.toLowerCase().replace(/[^A-Za-z0-9[\] ]/g, latinizeReplace),
    })),
    'id'
  )
    .sort((a, b) => a.name.localeCompare(b.name))
    .map(country => {
      const currentGoldCourses = golfCourses.filter(
        golfCourse => golfCourse.country.id === country.id
      );
      const latitude =
        currentGoldCourses.reduce((prev, curr) => prev + curr.latitude, 0) /
        currentGoldCourses.length;
      const longitude =
        currentGoldCourses.reduce((prev, curr) => prev + curr.longitude, 0) /
        currentGoldCourses.length;

      return {
        ...country,
        latitude,
        longitude,
      };
    });
};

const useCountries = (courses?: MappedGolfCourseType[]) => {
  const { data: golfCourses } = useGolfCourses();

  return getCountries(courses ?? golfCourses);
};

export default useCountries;
