import { useMemo } from 'react';
import useMediaQuery from './useMediaQuery';

type BreakpointType = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';

function useBreakpoints(): Record<BreakpointType, boolean> {
  const xs = useMediaQuery('(min-width: 0)');
  const sm = useMediaQuery('(min-width: 576px)');
  const md = useMediaQuery('(min-width: 768px)');
  const lg = useMediaQuery('(min-width: 992px)');
  const xl = useMediaQuery('(min-width: 1200px)');
  const xxl = useMediaQuery('(min-width: 1400px)');

  return useMemo(
    () => ({
      xs,
      sm,
      md,
      lg,
      xl,
      xxl
    }),
    [xs, sm, md, lg, xl, xxl]
  );
}

export default useBreakpoints;
