import { DOMAttributes, ReactElement, isValidElement } from 'react';

import deTranslation from '../assets/translations/de.json';
import dkTranslation from '../assets/translations/dk.json';
import enTranslation from '../assets/translations/en.json';
import noTranslation from '../assets/translations/no.json';
import svTranslation from '../assets/translations/sv.json';

export { enTranslation, svTranslation, noTranslation, dkTranslation, deTranslation };

export const languages = [
  {
    name: 'English',
    code: 'en',
  },
  {
    name: 'Swedish',
    code: 'sv',
  },
  {
    name: 'Norwegian',
    code: 'no',
  },
  {
    name: 'Danish',
    code: 'dk',
  },
  {
    name: 'German',
    code: 'de',
  },
];

export const handelDefaultTranslation = (
  defaultTranslation: string | ReactElement<DOMAttributes<any>>
) => {
  if (typeof defaultTranslation === 'string') {
    return defaultTranslation;
  }

  if (isValidElement(defaultTranslation)) {
    return defaultTranslation.props?.dangerouslySetInnerHTML?.__html;
  }
};

export const onMissingTranslation = ({
  defaultTranslation,
}: {
  defaultTranslation: string | ReactElement<DOMAttributes<any>>;
}) => handelDefaultTranslation(defaultTranslation);
