import TagManager from 'react-gtm-module';
import { BOOKING_STATUS_CONFIRMED } from '../constants';

const inProduction = process.env.REACT_APP_ENVIRONMENT === 'production';

if (inProduction) {
  TagManager.initialize({
    gtmId: 'GTM-PD778RV',
    events: {
      purchase: 'Order confirmed',
      user_register: 'User registered'
    }
  });
}

export const register = action => ({
  event: 'user_register',
  page: action.payload.page
});

export const orderComfirmed = order => {
  const dataLayer = {
    event: 'purchase',
    ecommerce: {
      transaction_id: order.id,
      value: parseFloat(order.price),
      currency: order.currency?.name
    }
  };

  const orderItems = order.orderItems
    .filter(
      oi =>
        oi.booking?.status === BOOKING_STATUS_CONFIRMED ||
        oi.accommodationBooking?.status === BOOKING_STATUS_CONFIRMED
    )
    .map(oi =>
      oi.booking
        ? {
            item_id: oi.booking.golfClubId,
            item_name: oi.booking.golfClub,
            quantity: oi.booking.nrPlayers,
            price: oi.booking.price,
            item_category: 'Greenfee'
          }
        : {
            item_id: oi.accommodationBooking.accommodationId,
            item_name: oi.accommodationBooking.accommodationName,
            quantity: 1,
            price: oi.accommodationBooking.price,
            item_category: 'Accommodation',
            item_category2: oi.accommodationBooking.accommodationType,
            item_category3: oi.accommodationBooking.priceCategory
          }
    );

  let combinedOrderItems = [];
  orderItems.reduce((acc, item) => {
    if (!acc[item.item_id]) {
      acc[item.item_id] = { ...item, quantity: 0, price: 0 };
      combinedOrderItems.push(acc[item.item_id]);
    }
    acc[item.item_id].quantity += item.quantity;
    acc[item.item_id].price += item.price;
    return acc;
  }, {});

  combinedOrderItems = combinedOrderItems.map(item => ({
    ...item,
    price: Math.round((item.price / item.quantity) * 10) / 10
  }));

  dataLayer.ecommerce.items = combinedOrderItems;

  return dataLayer;
};
