import React, { FC } from 'react';

import classNames from '../helpers/classNames';
import DatePicker, { DatePickerProps } from './DatePicker/DatePicker';

type CalenderPropsType = {
  selectedDate: Date;
} & DatePickerProps;

const Calendar: FC<CalenderPropsType> = props => {
  const { selectedDate, className, wrapperClassName, ...rest } = props;

  return (
    <div
      className={classNames(
        'd-flex align-items-center form-control',
        props.disabled && 'opacity-50'
      )}
    >
      <div
        className="icon-calendar flex-shrink-0 me-2"
        style={{
          fontSize: '18px',
        }}
      />

      <DatePicker
        closeOnScroll
        minDate={new Date()}
        selected={selectedDate}
        popperPlacement="bottom-start"
        wrapperClassName={classNames(wrapperClassName, 'flex-fill')}
        className={classNames(className, 'text-truncate')}
        {...rest}
        showPopperArrow={false}
      />
    </div>
  );
};

export default Calendar;
