import React, { FC, HTMLAttributes, useId, useMemo } from 'react';
import { DropdownToggleProps, UncontrolledPopover } from 'reactstrap';

import { replace } from 'lodash-es';

import classNames from '../../helpers/classNames';
import useBreakpoints from '../../hooks/useBreakpoints';
import styles from './MultilevelMenu.module.scss';

export type MenuItem = {
  href: string;
  label: string;
  id: string;
  parentId?: string;
};

interface MultilevelMenuProps extends HTMLAttributes<HTMLUListElement> {
  items: MenuItem[];
  levelId?: MenuItem['id'];
  toggleProps?: DropdownToggleProps;
}

const MultilevelMenu: FC<MultilevelMenuProps> = props => {
  const { levelId, items, className, ...rest } = props;
  const menuId = useId();
  const prefix = useMemo(() => replace(menuId, /:/g, '-'), [menuId]);
  const { md: enablePopover } = useBreakpoints();

  return (
    <ul className={classNames(styles.menu, styles.show, className)} {...rest}>
      {items
        .filter(item => item.parentId === levelId)
        .map(item => {
          const id = `popover-id${prefix}${item.id}`;

          return (
            <li key={item.id}>
              {items.some(subItem => subItem.parentId === item.id) ? (
                <>
                  <a id={id} href={item.href} className={styles.item}>
                    {item.label}
                    <span className={styles.caret} />
                  </a>
                  {enablePopover ? (
                    <UncontrolledPopover
                      // Fixes an issue with the TooltipPopoverWrapper.
                      // React reuses instances of class components without recreating them, so the TooltipPopoverWrapper breaks during rendering due to an internal design error.
                      // So force React to recreate this component at each rendering.
                      key={Math.random()}
                      target={id}
                      flip
                      placement="right-start"
                      trigger="hover"
                      hideArrow
                      offset={[0, 0]}
                    >
                      <MultilevelMenu items={items} levelId={item.id} />
                    </UncontrolledPopover>
                  ) : (
                    <MultilevelMenu items={items} levelId={item.id} />
                  )}
                </>
              ) : (
                <a href={item.href} className={styles.item}>
                  {item.label}
                </a>
              )}
            </li>
          );
        })}
    </ul>
  );
};

export default MultilevelMenu;
