import React, { FC, InputHTMLAttributes, ReactNode, useId } from 'react';
import type { RegisterOptions, UseFormRegister } from 'react-hook-form';
import { Label } from 'reactstrap';

interface InputProps
  extends Pick<
    InputHTMLAttributes<HTMLInputElement>,
    'type' | 'placeholder' | 'disabled' | 'onBlur' | 'autoComplete' | 'readOnly'
  > {
  error: ReactNode;
  helperText?: ReactNode;
  label: string;
  name: string;
  register: UseFormRegister<any>;
  rules: RegisterOptions;
}

const Input: FC<InputProps> = ({
  label,
  name,
  type = 'text',
  placeholder = label,
  rules,
  disabled,
  helperText,
  error,
  onBlur,
  register,
  autoComplete = 'on',
  readOnly,
}) => {
  const id = useId();
  return (
    <div className="mb-3">
      {label && (
        <Label htmlFor={id}>
          {label} {rules?.required && '*'}
        </Label>
      )}
      <input
        id={id}
        type={type}
        placeholder={placeholder}
        {...register(name, rules)}
        disabled={disabled}
        className={`form-control ${error && 'invalid'}`}
        onBlur={onBlur}
        autoComplete={autoComplete}
        readOnly={readOnly}
      />
      {helperText && <span className="small">{helperText}</span>}
      {error && <span className="small error d-flex mt-1">{error}</span>}
    </div>
  );
};

export default Input;
