import { useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { Translate } from 'react-localize-redux';
import { PulseLoader } from 'react-spinners';
import { Alert, Button, Col, Row } from 'reactstrap';

import { isValidEmail, isValidGolfId } from '../../../helpers/regex';
import useTranslate from '../../../hooks/useTranslate';
import Input from '../../form/Input';
import {
  isCardNrAlreadyAdded,
  isEmailAlreadyAdded,
  isGolfIdAlreadyAdded,
  validateCardNumber,
  validateCardNumberFormat,
  validateEmail,
  validateGolfId,
} from '../services/validation';

const defaultValues = {
  emailGolfId: '',
  cardNr: '',
  firstName: '',
  lastName: '',
};

export const ManualFriends = ({
  item,
  players,
  isGolfhaftetRequest,
  isThirdParty,
  onFriendAddClick,
}) => {
  const { activeLanguage, translate } = useTranslate();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    control,
  } = useForm(defaultValues);

  const emailGolfId = useWatch({ control, name: 'emailGolfId' });

  const [isValidating, setIsValidating] = useState(false);
  const [error, setError] = useState(null);

  const validateInputEmailOrGolfId = value => {
    if (['sv', 'dk'].includes(activeLanguage.code)) {
      if (value.includes('@')) {
        return validateInputEmail(value);
      } else {
        return validateInputGolfId(value);
      }
    } else {
      return validateInputEmail(value);
    }
  };

  const validateInputEmail = value => {
    let error = validateEmail(value);
    if (error) {
      return String(translate(error));
    }

    error = isEmailAlreadyAdded(players, value);
    if (error) {
      return String(translate(error));
    }

    return null;
  };

  const validateInputGolfId = value => {
    let error = validateGolfId(value);
    if (error) {
      return String(translate(error));
    }

    error = isGolfIdAlreadyAdded(players, value);
    if (error) {
      return String(translate(error));
    }

    return null;
  };

  const validateInputCardNr = value => {
    let error = validateCardNumberFormat(value);
    if (error) {
      return String(translate(error));
    }

    error = isCardNrAlreadyAdded(players, value);
    if (error) {
      return String(translate(error));
    }

    return null;
  };

  const isValidGolfhaftetCard = async userData => {
    setIsValidating(true);
    const error = await validateCardNumber(userData.ghCardNr, item.golfCourseId, item.startTime);
    setIsValidating(false);
    if (error) {
      setError(translate(error));
      return false;
    }
    return true;
  };

  const onSubmit = async data => {
    setError(null);
    const userData = isThirdParty
      ? {
          key: `${data.firstName}${data.lastName}`,
          firstName: data.firstName,
          lastName: data.lastName,
        }
      : {
          key: data.emailGolfId,
          email: isValidEmail(data.emailGolfId) ? data.emailGolfId : null,
          golfId: isValidGolfId(data.emailGolfId) ? data.emailGolfId : null,
          ghCardNr: data.cardNr,
        };

    if (isGolfhaftetRequest) {
      const valid = await isValidGolfhaftetCard(userData);
      if (!valid) {
        return;
      }
    }

    reset(defaultValues);
    onFriendAddClick(userData);
  };

  return (
    <>
      <p className="mt-3 mb-1">
        {isGolfhaftetRequest ? (
          <Translate id="default.CartAddPlayer.NewPlayerGH" />
        ) : isThirdParty ? (
          <Translate id="default.CartAddPlayer.NewPlayer" />
        ) : (
          <Translate id="PlayersSelectionComponentAddByEmailOrGolfId" />
        )}
      </p>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          {isThirdParty ? (
            <>
              <Col xs="12" sm="6">
                <Input
                  type="text"
                  name="firstName"
                  placeholder={translate('default.FirstName')}
                  disabled={isValidating}
                  register={register}
                  rules={{ required: true }}
                  error={
                    errors.firstName &&
                    translate('default.CartValidationErrors.EmptyPlayerFirstName')
                  }
                />
              </Col>
              <Col xs="12" sm="6">
                <Input
                  type="text"
                  name="lastName"
                  placeholder={translate('default.LastName')}
                  disabled={isValidating}
                  register={register}
                  rules={{ required: true }}
                  error={
                    errors.lastName && translate('default.CartValidationErrors.EmptyPlayerLastName')
                  }
                />
              </Col>
            </>
          ) : (
            <Col xs="12" sm="8">
              <Input
                type="text"
                name="emailGolfId"
                placeholder={translate('default.EmailOrGolfId')}
                disabled={isValidating}
                register={register}
                rules={{ validate: validateInputEmailOrGolfId }}
                error={errors.emailGolfId?.message}
              />
            </Col>
          )}
          {isGolfhaftetRequest && (
            <Col xs="12" sm="12">
              <Input
                type="text"
                name="cardNr"
                placeholder={translate('default.CartAddPlayer.PlaceholderCardNumber')}
                disabled={isValidating}
                register={register}
                rules={{ validate: validateInputCardNr }}
                error={errors.cardNr?.message}
              />
            </Col>
          )}
          <Col xs="12">
            {error && (
              <Alert color="danger" className="p-2">
                {error}
              </Alert>
            )}
            <Button color="success" disabled={!emailGolfId}>
              {isValidating && <PulseLoader size={10} />}
              <Translate id="default.Add" />
            </Button>
          </Col>
        </Row>
      </form>
    </>
  );
};
