// import axios from "axios";
import { post, get } from '../http';

/* oauth */

export const logIn = async (username, password) => {
  try {
    const data = await post(
      `connect/token/`,
      {},
      {
        client_id: 'app',
        client_secret: 'OnTeeRulesForever953214',
        grant_type: 'password',
        scope: 'api1',
        username,
        password
        // "api-version": "1.0"
      }
    );
    return data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const register = async ({
  firstName,
  lastName,
  email,
  password,
  confirmPassword,
  phone,
  golfId,
  // handicap,
  gender,
  memberIn,
  nationality,
  code
}) => {
  if (golfId && golfId.length === 9) {
    const start = golfId.slice(0, 6);
    const end = golfId.slice(6, 9);
    golfId = `${start}-${end}`;
  }

  const regForm = {
    FirstName: firstName,
    LastName: lastName,
    Email: email,
    Password: password,
    ConfirmPassword: confirmPassword,
    TelNumber: phone,
    GolfId: golfId,
    Gender: gender,
    // Handicap: 36,
    Origin: 'website',
    CampaignCode: code
  };

  if (memberIn && memberIn.length > 0) {
    regForm.MemberIn = memberIn;
  }

  if (nationality && nationality > 0) {
    regForm.CountryId = nationality;
  }

  // /Account/Register
  const user = await post(`Account/Register`, null, regForm);

  return user;
};

export const forgotPassword = async email => {
  const user = await post(
    `Account/ForgotPassword`,
    {},
    {
      Email: email
    }
  );
  return user;
};

export const resetPassword = async data => {
  const result = await post(`Account/ResetPassword`, data, {});
  return result;
};

export const checkIfLogedIn = () => get(`v2/Profiles/me`, null, false);

const auth = {
  logIn,
  checkIfLogedIn,
  register,
  forgotPassword,
  resetPassword
};

export default auth;
