import { uniqBy } from 'lodash-es';

import { latinizeReplace } from '../../language/latinize';
import useGolfCourses from '../golfCourses/api';
import { MappedGolfCourseType } from '../golfCourses/types';
import { MappedDistrictType } from './types';

export const getDistricts = (golfCourses?: MappedGolfCourseType[]): MappedDistrictType[] => {
  if (!golfCourses) return [];

  return uniqBy(
    golfCourses.map(({ district, country }) => ({
      ...district,
      countryId: country.id,
      search: district.name.toLowerCase().replace(/[^A-Za-z0-9[\] ]/g, latinizeReplace),
    })),
    'id'
  )
    .sort((a, b) => a.name.localeCompare(b.name))
    .map(district => {
      const currentGoldCourses = golfCourses.filter(
        golfCourse => golfCourse.country.id === district.id
      );
      const latitude =
        currentGoldCourses.reduce((prev, curr) => prev + curr.latitude, 0) /
        currentGoldCourses.length;
      const longitude =
        currentGoldCourses.reduce((prev, curr) => prev + curr.longitude, 0) /
        currentGoldCourses.length;

      return {
        ...district,
        latitude,
        longitude,
      };
    });
};

const useDistricts = () => {
  const { data: golfCourses } = useGolfCourses();

  return getDistricts(golfCourses);
};

export default useDistricts;
