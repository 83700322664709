import React, { useCallback, useMemo } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { Container } from 'reactstrap';

import { useAccommodationFilter } from '../../hooks/useAccommodationFilter';
import useBenefitsVisibility from '../../hooks/useBenefitsVisibility';
import useStore from '../../store/useStore';
import Benefits from '../Benefits/Benefits';
import GolfamoreUser from '../GolfamoreUser';
import NavbarMenu from '../NavbarMenu/NavbarMenu';
import SearchBar from '../SearchBar/SearchBar';
import { SelectedOptionType } from '../SearchBarSwitcher/SearchBarSwitcher';
import StickyHeader from '../StickyHeader/StickyHeader';
import VouchersBanner from '../VouchersBanner/VouchersBanner';
import { voucherBannerDefaultAmount } from '../VouchersBanner/defaultAmount';
import { nonBookingGolfClubSegments } from './config';
import { HEADER_ELEMENT_ID } from './consts';

const Navbar: React.FC = () => {
  const location = useLocation();
  const [, setAccommodationFilter] = useAccommodationFilter();
  const { pathname } = location;
  const { showBenefitsOnDesktop: showBenefits } = useBenefitsVisibility();

  const isGolfhaftetRequest = useStore(state => state.isGolfhaftetRequest);

  const { showSearchBar } = useMemo(() => {
    const matchBookingPages = matchPath('/:locale/booking/:clubId/:courseId', pathname);
    const matchCoursesPages = matchPath('/:locale/courses/*', pathname);
    const matchCampingPages = matchPath('/:locale/camping/*', pathname);
    const ignoreSegment =
      matchBookingPages &&
      matchBookingPages.params?.clubId &&
      nonBookingGolfClubSegments.includes(matchBookingPages.params.clubId);

    return {
      showSearchBar:
        (matchBookingPages && !ignoreSegment) || !!matchCoursesPages || !!matchCampingPages,
    };
  }, [pathname]);

  const handleFilterChange = useCallback(
    (value: SelectedOptionType) => {
      setAccommodationFilter(value === 'accommodation');
    },
    [setAccommodationFilter]
  );

  return (
    <>
      <StickyHeader id={HEADER_ELEMENT_ID} className={'shadow-sm'}>
        {isGolfhaftetRequest ? (
          <GolfamoreUser />
        ) : (
          <VouchersBanner {...voucherBannerDefaultAmount} />
        )}
        {!isGolfhaftetRequest && <NavbarMenu />}
        {showSearchBar && <SearchBar onFilterChange={handleFilterChange} style={{ height: 0 }} />}
      </StickyHeader>
      {showBenefits && (
        <Container className="my-4">
          <Benefits />
        </Container>
      )}
    </>
  );
};

export default Navbar;
