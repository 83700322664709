import haversine, { CoordinateLongitudeLatitude } from 'haversine';

interface DistanceObject {
  distance: number;
}

export const calculateDistance = (
  start: CoordinateLongitudeLatitude,
  end: CoordinateLongitudeLatitude
): number => haversine(start, end, { unit: 'km' });

export const sortDistance = (a: DistanceObject, b: DistanceObject) =>
  Math.sign(a.distance - b.distance);
