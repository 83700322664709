import { Slice } from './types';

interface GolfhaftetUserType {
  cardNr: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  country: string | null;
  returnUrl: string | null;
  appBackUrl: string | null;
  clubId: number;
  acceptedTerms: boolean;
  proceedAsGuest: boolean;
}

interface GuestUserType {
  email: string;
  firstName: string;
  lastName: string;
  authorizationToken?: string;
}

export interface CreateUserSlice {
  token: string;
  isLoggedIn: boolean;
  login: (token: string) => void;
  logout: () => void;

  golfhaftetUser: GolfhaftetUserType | null;
  isGolfhaftetRequest: boolean;
  setGolfamoreUser: (ghUser: GolfhaftetUserType | null) => void;
  setIsGolfamoreRequest: (val: boolean) => void;

  guest: GuestUserType | null;
  setGuest: (guest: GuestUserType | null) => void;
}

const createUserSlice: Slice<CreateUserSlice> = set => ({
  token: '',
  isLoggedIn: false,
  login: token =>
    set(() => ({
      token,
      guest: null,
      isLoggedIn: true,
    })),
  logout: () =>
    set(() => ({
      token: '',
      guest: null,
      isLoggedIn: false,
    })),

  golfhaftetUser: null,
  isGolfhaftetRequest: false,
  setGolfamoreUser: ghUser =>
    set(() => ({
      golfhaftetUser: ghUser,
      isGolfhaftetRequest: !!ghUser,
    })),
  setIsGolfamoreRequest: val =>
    set(() => ({
      isGolfhaftetRequest: val,
    })),

  guest: null,
  setGuest: guest => set(() => ({ guest })),
});

export default createUserSlice;
