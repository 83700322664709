import React, { FC, HTMLAttributes, useCallback, useEffect, useMemo, useState } from 'react';
import { Translate } from 'react-localize-redux';
import { useIsFetching } from 'react-query';
import { Link, useNavigate } from 'react-router-dom';
import { Col, Collapse, Container, Row } from 'reactstrap';

import { isObject } from 'lodash-es';
import ArrowRightIcon from 'material-react-icons/ArrowForward';

import classNames from '../../helpers/classNames';
import { formatISODate } from '../../helpers/formatISODate';
import {
  ACCOMMODATION_PARAM,
  ACCOMMODATION_VALUE,
  useAccommodationFilter,
} from '../../hooks/useAccommodationFilter';
import useBreakpoints from '../../hooks/useBreakpoints';
import { END_DATE_PARAM, START_DATE_PARAM, useDatesFilter } from '../../hooks/useDatesFilter';
import useTranslate from '../../hooks/useTranslate';
import { QUERY_KEY_ACCOMMODATION_ITEMS } from '../../services/apis/accommodation/api';
import { MappedCityType } from '../../services/apis/cities/types';
import { MappedCountryType } from '../../services/apis/countries/types';
import { MappedDistrictType } from '../../services/apis/districts/types';
import getGolfClubInfo from '../../services/apis/golfCourses/getGolfClubInfo';
import { MappedGolfCourseType } from '../../services/apis/golfCourses/types';
import useSearchData from '../../services/apis/search/useSearchData';
import { QUERY_KEY_TEETIMES } from '../../services/apis/teeTimes/api';
import useStore from '../../store/useStore';
import Button from '../Button/Button';
import Calendar from '../Calendar';
import SearchBarSwitcher, { SelectedOptionType } from '../SearchBarSwitcher/SearchBarSwitcher';
import SearchBarTabs from '../SearchBarTabs/SearchBarTabs';
import SearchField from '../SearchField';
import styles from './SearchBar.module.scss';

const generateUrl = (
  languageCode: string,
  entity: MappedGolfCourseType | MappedCountryType | MappedDistrictType | MappedCityType | null,
  {
    startDate,
    endDate,
    isAccommodationFilter,
  }: {
    startDate: Date;
    endDate?: Date;
    isAccommodationFilter: boolean;
  }
) => {
  const url = new URL(window.location.origin);
  url.pathname = `/${languageCode}`;

  url.searchParams.set(START_DATE_PARAM, formatISODate(startDate));

  if (isAccommodationFilter) {
    url.searchParams.set(ACCOMMODATION_PARAM, ACCOMMODATION_VALUE);

    if (endDate) {
      url.searchParams.set(END_DATE_PARAM, formatISODate(endDate));
    }
  } else {
    url.searchParams.delete(END_DATE_PARAM);
  }

  if (!entity) {
    return url.pathname + '/courses' + url.search;
  }

  if ('golfClub' in entity) {
    url.pathname += `/booking/${entity.golfClub.name}/${entity.golfCourse.name}/`;
  } else if ('districtId' in entity) {
    url.pathname += `/courses/cities/${entity.name}/`;
  } else if ('countryId' in entity) {
    url.pathname += `/courses/districts/${entity.name}/`;
  } else {
    url.pathname += `/courses/countries/${entity.name}/`;
  }

  return url.pathname + url.search;
};

interface SearchBarProps extends HTMLAttributes<HTMLDivElement> {
  layout?: 'main';
  onFilterChange?: (value: SelectedOptionType) => void;
}

const isGolfCourse = (entity: unknown): entity is MappedGolfCourseType => {
  return isObject(entity) && 'golfCourse' in entity;
};

const SearchBar: FC<SearchBarProps> = props => {
  const { layout, onFilterChange, ...rest } = props;
  const [isAccommodationFilter] = useAccommodationFilter();
  const selectedFilter: SelectedOptionType = isAccommodationFilter ? 'accommodation' : 'tee_times';
  const isBreakpoint = useBreakpoints();
  const isMobileBreakpoint = !isBreakpoint.md;
  const { startDate, endDate, setDates } = useDatesFilter();
  const [datesRange, setDatesRange] = useState<{ startDate: Date; endDate: Date | undefined }>({
    startDate,
    endDate,
  });

  const [isOpen, setIsOpen] = useState(false);

  const { translate } = useTranslate();

  const navigate = useNavigate();

  const languageCode = useStore(state => state.language);
  const isGolfhaftetRequest = useStore(state => state.isGolfhaftetRequest);

  const [searchValue, setSearchValue] = useState('');

  const { findMatchingEntity } = useSearchData();

  const { selectedGolfCourseId, accommodationId } = useMemo(() => {
    const matchingEntity = findMatchingEntity(searchValue);
    const golfCourse = isGolfCourse(matchingEntity) ? matchingEntity : null;

    return {
      selectedGolfCourseId: golfCourse?.golfCourse?.id,
      accommodationId: golfCourse && getGolfClubInfo(golfCourse)?.accommodationId,
    };
  }, [findMatchingEntity, searchValue]);

  const isTeeTimesFetching = !!useIsFetching({
    queryKey: [QUERY_KEY_TEETIMES, selectedGolfCourseId, formatISODate(startDate)],
  });

  const isAccommodationFetching = !!useIsFetching({
    queryKey: [
      QUERY_KEY_ACCOMMODATION_ITEMS,
      accommodationId,
      formatISODate(startDate),
      formatISODate(endDate || startDate),
    ],
  });

  const isLoading = isAccommodationFetching || isTeeTimesFetching;

  const onSearchButtonClick = useCallback(() => {
    const matchingEntity = findMatchingEntity(searchValue);

    const url = generateUrl(languageCode, matchingEntity, {
      startDate,
      endDate,
      isAccommodationFilter,
    });

    navigate(url);
  }, [
    endDate,
    findMatchingEntity,
    isAccommodationFilter,
    languageCode,
    navigate,
    searchValue,
    startDate,
  ]);

  const onAutosuggestionSelect = useCallback(
    (
      results: MappedGolfCourseType | MappedCountryType | MappedDistrictType | MappedCityType | null
    ) => {
      if (!results) {
        return;
      }

      const url = generateUrl(languageCode, results, {
        startDate,
        endDate,
        isAccommodationFilter,
      });

      navigate(url);
    },
    [endDate, isAccommodationFilter, languageCode, navigate, startDate]
  );

  const onDateSelect = useCallback(
    (date: Date | null) => {
      if (!date) {
        return;
      }

      setDates(date, undefined);
    },
    [setDates]
  );

  const onDatesSelect = useCallback(
    ([newStartDate, newEndDate]: [Date | null, Date | null]) => {
      if (!newStartDate) {
        return;
      }

      setDatesRange({ startDate: newStartDate, endDate: newEndDate || undefined });

      if (newStartDate && newEndDate) {
        setDates(newStartDate, newEndDate || undefined);
      }
    },
    [setDates]
  );

  useEffect(() => {
    setDatesRange({ startDate, endDate });
  }, [startDate, endDate]);

  if (isGolfhaftetRequest) {
    return null;
  }

  const calendarElement = isAccommodationFilter ? (
    <Calendar
      selectedDate={datesRange.startDate}
      startDate={datesRange.startDate}
      endDate={datesRange.endDate}
      selectsRange
      monthsShown={1}
      disabled={isLoading}
      disabledKeyboardNavigation
      onChange={onDatesSelect}
      onFocus={() => setIsOpen(true)}
    />
  ) : (
    <Calendar
      selectedDate={startDate}
      onChange={onDateSelect}
      disabled={isLoading}
      onFocus={() => setIsOpen(true)}
    />
  );

  const searchFieldElement = (
    <SearchField
      onSearchSelect={onAutosuggestionSelect}
      placeholder={String(translate('SearchBarInputPlaceholder'))}
      onFocus={() => {
        setIsOpen(true);
      }}
      onValueChange={setSearchValue}
      disabled={isLoading}
      className={'d-flex flex-fill align-items-center'}
    />
  );

  // main page
  if (layout === 'main') {
    return (
      <>
        <SearchBarTabs selectedOption={selectedFilter} onChange={onFilterChange} />
        <div className={styles.container}>
          <Row>
            <Col xs="12" lg="7">
              {searchFieldElement}
            </Col>
            <Col xs="12" lg="3" className="mt-3 mt-lg-0">
              {calendarElement}
            </Col>
            <Col xs="12" lg="2" className="mt-3 mt-lg-0">
              <Button
                primary
                className="w-100 justify-content-center"
                onClick={onSearchButtonClick}
                disabled={isLoading}
                loading={isLoading}
              >
                <Translate id="default.Search" />
                <ArrowRightIcon />
              </Button>
            </Col>
          </Row>
          <div className={classNames('mt-3', styles.notice)}>
            {translate(isAccommodationFilter ? 'search.notice-accommodation' : 'search.notice')}
          </div>
        </div>
      </>
    );
  }

  return (
    <div {...rest}>
      {isMobileBreakpoint ? (
        <div className="search-bar rounded-0" style={{ boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)' }}>
          <div className="d-flex gap-2">
            <div
              style={{
                flex: 7,
                minWidth: 0,
              }}
            >
              {searchFieldElement}
            </div>
            {!isOpen && (
              <div className={'d-flex'} style={{ flex: 3, minWidth: 0 }}>
                {calendarElement}
              </div>
            )}
          </div>
          <Collapse isOpen={isOpen}>
            <div className={'d-flex flex-column gap-3 mt-3'}>
              <div className="d-flex align-items-center gap-3">
                <SearchBarSwitcher
                  hideLabels
                  selectedOption={selectedFilter}
                  onChange={onFilterChange}
                  disabled={isLoading}
                />
                {calendarElement}
              </div>

              <div className="d-flex justify-content-center gap-3 flex-nowrap">
                <Button
                  primary
                  className="flex-grow-1 justify-content-center"
                  onClick={onSearchButtonClick}
                  disabled={isLoading}
                  loading={isLoading}
                >
                  <Translate id="default.Search" />
                </Button>
                <Button tertiary onClick={() => setIsOpen(false)}>
                  <Translate id="default.close" />
                </Button>
              </div>

              <div className="text-center">
                <Link to={`/${languageCode}/courses/`}>
                  <Translate id="default.showAllCourses" />
                </Link>
              </div>
            </div>
          </Collapse>
        </div>
      ) : (
        // other pages, desktop
        <Container
          fluid="lg"
          className="search-bar"
          style={{ boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)' }}
        >
          <Row>
            <Col xs="12" md="7" className={'d-flex align-items-center gap-3'}>
              <SearchBarSwitcher
                hideLabels
                selectedOption={selectedFilter}
                onChange={onFilterChange}
                disabled={isLoading}
              />
              {searchFieldElement}
            </Col>
            <Col xs="12" md="3" className={'d-flex align-items-center'}>
              {calendarElement}
            </Col>
            <Col xs="12" md="2" className={'d-flex align-items-center'}>
              <Button
                primary
                className="w-100 justify-content-center"
                onClick={onSearchButtonClick}
                disabled={isLoading}
                loading={isLoading}
              >
                <Translate id="default.Search" />
              </Button>
            </Col>
          </Row>
          <div className="mt-2 text-end">
            <Link to={`/${languageCode}/courses/`}>
              <Translate id="default.showAllCourses" />
            </Link>
          </div>
        </Container>
      )}
    </div>
  );
};

export default SearchBar;
