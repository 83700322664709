import { add } from 'date-fns';

import { parseDateInUTC } from '../../../helpers/datetime';
import { isPlayersRequired } from './validation';

export const extractOrderItems = (
  order,
  isThirdParty = false,
  profile = null,
  youTranslation = null
) =>
  order?.orderItems.map(
    i =>
      (i.booking && {
        id: i.id,
        bookingId: i.booking.id,
        orderRef: i.booking.orderRef,
        label: i.name || `${i.booking.golfClub} - ${i.booking.golfCourse}`,
        golfCourseId: i.booking.golfCourseId,
        startTime: parseDateInUTC(i.booking.startTime),
        endTime: parseDateInUTC(i.booking.startTime),
        status: i.booking.status,
        currency: i.booking.currency,
        price: i.booking.price,
        amount: i.booking.nrPlayers,
        products: parseProducts(i),
        extraProducts: parseExtraProducts(i.booking),
        prepayRequired: i.booking.prepayRequired,
        type: 'booking',
        playersRequired: isPlayersRequired(i.booking, isThirdParty),
        cancelBookingHours: i.booking.cancelBookingHours,
        expired: i.expired,
        fromGolfhaftet: i.booking.fromGolfhaftet,
        playersValidated: i.playersValidated,
        random: order.random,
        userBookings: i.booking.userBookings,
        users: i.booking.userBookings?.map(ub => ({
          key: ub.profileId || ub.user?.id || ub.user?.key || ub.user?.email || ub.user?.golfId,
          id: ub.user?.id,
          name:
            profile?.id === ub.user?.id
              ? youTranslation || 'You'
              : ub.user?.firstName
              ? `${ub.user?.firstName} ${ub.user?.lastName}`
              : null,
          firstName: ub.user?.firstName,
          lastName: ub.user?.lastName,
          email: ub.user?.mail,
          golfId: ub.user?.golfId,
          ghCardNr: ub.user?.ghCardNr,
          isLoggedInProfile: profile?.id === ub.user?.id,
          price: ub.price > 0 ? ub.price + i.booking.currency : null,
        })),
      }) ||
      (i.accommodationBooking && {
        id: i.id,
        bookingId: i.accommodationBooking.id,
        orderRef: i.accommodationBooking.orderRef,
        label: i.accommodationBooking.accommodationName,
        startTime: parseDateInUTC(i.accommodationBooking.checkinDate),
        endTime: parseDateInUTC(i.accommodationBooking.checkoutDate),
        status: i.accommodationBooking.status,
        currency: i.accommodationBooking.currency,
        price: i.accommodationBooking.price,
        amount: i.accommodationBooking.numberOfGuests,
        products: parseProducts(i),
        prepayRequired: i.accommodationBooking.prepayRequired,
        type: 'accommodation',
        expired: i.expired,
      }) ||
      (i.product && {
        id: i.id,
        label:
          i.product.productCode === 'GCD'
            ? i.product.email
            : `${i.product.firstName} ${i.product.lastName}`,
        type: 'product',
        valid: true,
        productCode: i.product.productCode,
        status: i.product.status,
        price: i.product.price,
        currency: i.product.currency,
        shippingCost: i.product.shippingCost,
        prepayRequired: true,
        // valid date = current date + 2 years, when we get order details we don't know valid date, backend adds it on the further stages, so we calculate it on frontend as well.
        validUntil: add(new Date(), { years: 2 }),
        address:
          i.product.productCode === 'GC'
            ? [i.product.address, i.product.city, i.product.country, i.product.postalCode].join(
                ', '
              )
            : '',
      })
  );

const parseExtraProducts = booking => {
  let extraProducts = null;
  try {
    if (booking.extraProducts) {
      extraProducts = JSON.parse(booking.extraProducts);
    }
  } catch (e) {
    extraProducts = null;
  }
  return extraProducts;
};

const parseProducts = orderItem => orderItem.itemInfo?.lines.map(l => `${l.amount} x ${l.name}`);
