import { useQuery } from 'react-query';

import { get } from '../../http';
import { latinizeReplace } from '../../language/latinize';
import { CityResponseType, MappedCityType } from './types';

export const QUERY_KEY_CITIES = 'cities';

const fetchCities = () => get<CityResponseType>('v2/Cities');

export const getCities = async (): Promise<MappedCityType[] | undefined> => {
  const cities = await fetchCities();

  return cities
    ?.map(city => ({
      ...city,
      search: city.name.toLowerCase().replace(/[^A-Za-z0-9[\] ]/g, latinizeReplace),
    }))
    .sort((a, b) => a.name.localeCompare(b.name));
};
const useCities = () => useQuery(QUERY_KEY_CITIES, getCities);

export default useCities;
