import React, { FC, useMemo } from 'react';
import { Helmet as ReactHelmet, HelmetProps as ReactHelmetProps } from 'react-helmet';
import { TranslateOptions, TranslatePlaceholderData } from 'react-localize-redux';
import { useLocation } from 'react-router-dom';

import { TranslationKeys } from '../../TranlsationKeys';
import useTranslate from '../../hooks/useTranslate';

const onMissingTranslation: TranslateOptions['onMissingTranslation'] = ({
  defaultTranslation,
  translationId,
}) =>
  defaultTranslation !==
  "No default translation found! Ensure you've added translations for your default language."
    ? defaultTranslation
    : translationId;

interface HelmetProps {
  titleTranslationKey?: TranslationKeys;
  descriptionTranslationKey?: TranslationKeys;
  title?: string;
  description?: string;
  titleData?: TranslatePlaceholderData;
  descriptionData?: TranslatePlaceholderData;
}

const Helmet: FC<HelmetProps> = props => {
  const {
    titleTranslationKey,
    descriptionTranslationKey,
    title,
    description,
    titleData = {},
    descriptionData = {},
  } = props;
  const { translate, languages, activeLanguage } = useTranslate();
  const { pathname } = useLocation();

  const helmetProps = useMemo<ReactHelmetProps>(() => {
    const result: ReactHelmetProps = {};

    result.link = [
      { rel: 'canonical', href: `${window.location.origin}${pathname}` },
      /**
       * @see
       * https://developers.google.com/search/docs/specialty/international/localized-versions#html
       */
      ...languages.map(language => ({
        rel: 'alternate',
        href: `${window.location.origin}${pathname.replace(
          new RegExp(`^/${activeLanguage.code}/`),
          `/${language.code}/`
        )}`,
        /**
         * @see
         * https://developers.google.com/search/docs/specialty/international/localized-versions#language-codes
         * https://en.wikipedia.org/wiki/List_of_ISO_639_language_codes
         */
        hrefLang: language.code === 'dk' ? 'da' : language.code,
      })),
      /**
       * @see
       * https://developers.google.com/search/docs/specialty/international/localized-versions?hl=ru#all-method-guidelines
       */
      {
        rel: 'alternate',
        href: `${window.location.origin}${pathname.replace(
          new RegExp(`^/${activeLanguage.code}/`),
          '/en/'
        )}`,
        hrefLang: 'x-default',
      },
    ];

    result.title = titleTranslationKey
      ? `${translate(titleTranslationKey, titleData, {
          onMissingTranslation,
        })} | OnTee.com`
      : title
      ? `${title}  | OnTee.com`
      : 'OnTee.com';

    result.htmlAttributes = { lang: activeLanguage.code === 'dk' ? 'da' : activeLanguage.code };

    const metaDescription = descriptionTranslationKey
      ? String(
          translate(descriptionTranslationKey, descriptionData, {
            onMissingTranslation,
          })
        )
      : description
      ? description
      : 'OnTee';
    result.meta = [
      {
        name: 'description',
        content: metaDescription,
      },
      {
        property: 'og:description',
        content: metaDescription,
      },
      {
        property: 'og:title',
        content: title,
      },
      { property: 'twitter:title', content: title },

      { property: 'twitter:description', content: metaDescription },
    ];

    return result;
  }, [
    activeLanguage.code,
    description,
    descriptionData,
    descriptionTranslationKey,
    languages,
    pathname,
    title,
    titleData,
    titleTranslationKey,
    translate,
  ]);

  return <ReactHelmet {...helmetProps} />;
};

export default Helmet;
