import { Friends } from './Friends';
import { ManualFriends } from './ManualFriends';
import { PlayersInFlight } from './PlayersInFlight';

export function Players({
  players,
  removePlayer,
  userFriends,
  onFriendAddClick,
  isGolfhaftetRequest,
  isThirdParty,
  isGitBooking,
  item,
  disabled,
}) {
  const seatsInFlight = item?.amount;
  const playersInFlight = players?.length;
  const flightFull = playersInFlight === item?.amount;

  return (
    <div>
      <PlayersInFlight
        item={item}
        players={players}
        playersInFlight={playersInFlight}
        seatsInFlight={seatsInFlight}
        flightFull={flightFull}
        removePlayer={removePlayer}
        isGolfhaftetRequest={isGolfhaftetRequest}
        isGitBooking={isGitBooking}
        disabled={disabled}
      />

      {!flightFull && userFriends?.length > 0 && (
        <Friends
          item={item}
          players={players}
          friends={userFriends}
          flightFull={flightFull}
          onFriendAddClick={onFriendAddClick}
          isGolfhaftetRequest={isGolfhaftetRequest}
        />
      )}

      {!flightFull && (
        <ManualFriends
          item={item}
          players={players}
          isGolfhaftetRequest={isGolfhaftetRequest}
          isThirdParty={isThirdParty}
          onFriendAddClick={onFriendAddClick}
        />
      )}
    </div>
  );
}
