import React, { FC, HTMLAttributes, PropsWithChildren } from 'react';

import classNames from '../../helpers/classNames';
import styles from './Header.module.scss';

export const Header: FC<PropsWithChildren<HTMLAttributes<HTMLHeadingElement>>> = ({
  children,
  ...rest
}) => (
  <h2 {...rest} className={classNames(styles.header, rest.className)}>
    {children}
  </h2>
);
