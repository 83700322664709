import { useState } from 'react';
import { Translate } from 'react-localize-redux';
import SimpleLineIcons from 'react-simple-line-icons';
import { PulseLoader } from 'react-spinners';
import { Alert, Button } from 'reactstrap';

import useTranslate from '../../../hooks/useTranslate';
import {
  isCardNrAlreadyAdded,
  validateCardNumber,
  validateCardNumberFormat,
} from '../services/validation';

export const Friends = ({
  item,
  players,
  friends,
  flightFull,
  onFriendAddClick,
  isGolfhaftetRequest,
}) => {
  const { translate } = useTranslate();

  const [isValidating, setIsValidating] = useState(false);
  const [error, setError] = useState(null);

  const onFriendClick = async friend => {
    if (isGolfhaftetRequest) {
      const valid = await isValidGolfhaftetCard(friend);
      if (!valid) {
        return;
      }
    }

    onFriendAddClick(friend);
  };

  const isValidGolfhaftetCard = async friend => {
    const friendId = friend.id;

    setError(null);

    let error = validateCardNumberFormat(friend.ghCardNr);
    if (error) {
      setError({ friendId, message: translate(error) });
      return false;
    }

    error = isCardNrAlreadyAdded(players, friend.ghCardNr);
    if (error) {
      setError({ friendId, message: translate(error) });
      return false;
    }

    setIsValidating(friend.id);
    error = await validateCardNumber(friend.ghCardNr, item.golfCourseId, item.startTime);
    setIsValidating(false);
    if (error) {
      setError({ friendId, message: translate(error) });
      console.log('error return false', error);

      return false;
    }

    return true;
  };

  return (
    <>
      <p className="mt-3 mb-1">
        <Translate id="default.AddFriend" />
      </p>

      {friends?.map(u => (
        <Button
          key={u.id || u.key}
          className="border"
          block
          color="link"
          disabled={flightFull || !!isValidating}
          onClick={() => onFriendClick(u)}
        >
          <div className="d-flex py-2 px-3 mb-1 player">
            <SimpleLineIcons size="medium" name="plus" /> {u.name || u.email || u.golfId}{' '}
            {isGolfhaftetRequest && u.ghCardNr && ` - ${u.ghCardNr}`}
            {isValidating === u.id && <PulseLoader size={10} />}
          </div>
          {error && error?.friendId === u.id && (
            <Alert color="danger" className="p-1">
              {error.message}
            </Alert>
          )}
        </Button>
      ))}
    </>
  );
};
