import { useEffect } from 'react';

const SENSITIVITY = 20;
const SPEED = 0.5;

/**
 * Hook that detects user's intent to leave the page and shows a newsletter popup
 */
export const useExitIntent = (callback: () => void) => {
  useEffect(() => {
    // Track mouse position and movement
    let mouseY = 0;
    let lastMoveTime = Date.now();
    let lastMoveSpeed = 0;

    /**
     * Handles mouse movement to detect exit intent
     * Shows popup when user moves mouse quickly towards the top of the page
     */
    const handleMouseMove = (e: MouseEvent) => {
      const currentTime = Date.now();
      const timeDiff = currentTime - lastMoveTime;
      const moveDistance = mouseY - e.clientY;

      // Calculate mouse movement speed in pixels per millisecond
      lastMoveSpeed = timeDiff > 0 ? Math.abs(moveDistance / timeDiff) : 0;

      // Trigger callback if:
      // 1. Mouse is moving upward (moveDistance > 0)
      // 2. Cursor is near the top of the screen (e.clientY < sensitivity)
      // 3. Movement speed is high enough (> 0.5 px/ms) to indicate intentional movement
      if (moveDistance > 0 && e.clientY < SENSITIVITY && lastMoveSpeed > SPEED) {
        callback();
      }

      // Update tracking variables
      mouseY = e.clientY;
      lastMoveTime = currentTime;
    };

    const handleMouseLeave = (e: MouseEvent) => {
      if (e.clientY <= 0) {
        callback();
      }
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseleave', handleMouseLeave);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseleave', handleMouseLeave);
    };
  }, [callback]);
};
