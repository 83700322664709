import React from 'react';
import { withLocalize } from 'react-localize-redux';
import { Button, Card, CardBody, CardHeader, Modal } from 'reactstrap';
import useStore from '../store/useStore';

function InfoModal({ translate }) {
  const appInfo = useStore(state => state.appInfo);
  const clearAppInfo = useStore(state => state.clearAppInfo);

  return (
    <Modal isOpen toggle={clearAppInfo} centered>
      <Card>
        <CardHeader>
          <h2>{translate(appInfo.title)}</h2>
        </CardHeader>
        <CardBody className="pt-1">{translate(appInfo.text)}</CardBody>
        <Button color="success" onClick={clearAppInfo} style={{ borderRadius: 0 }}>
          OK
        </Button>
      </Card>
    </Modal>
  );
}

export default withLocalize(InfoModal);
