import React, { FC, MouseEventHandler, PropsWithChildren, useCallback, useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import useTranslate from '../hooks/useTranslate';

interface ExternalLinkWithModalProps {
  component: FC;
  href: string;
  isGolfhaftetRequest: boolean;
}

export const ExternalLinkWithModal: FC<PropsWithChildren<ExternalLinkWithModalProps>> = props => {
  const { component: Component, href, isGolfhaftetRequest, children } = props;
  const [visible, setVisible] = useState(false);
  const { translate } = useTranslate();

  const handleClick = useCallback<MouseEventHandler<HTMLAnchorElement>>(
    e => {
      if (window.top !== window || isGolfhaftetRequest) {
        e.preventDefault();
        setVisible(true);
      }
    },
    [isGolfhaftetRequest]
  );

  const handleToggle = () => setVisible(v => !v);

  return (
    <>
      {visible && (
        <Modal isOpen centered toggle={handleToggle}>
          <ModalHeader toggle={handleToggle} className="px-3 pt-3" />
          <ModalBody>
            <Component />
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={handleToggle}>
              {translate('Register.form.termsCheckbox.close_modal_cta')}
            </Button>
          </ModalFooter>
        </Modal>
      )}
      <a href={href} target="_blank" rel="noreferrer" onClick={handleClick}>
        {children}
      </a>
    </>
  );
};
