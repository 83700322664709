import localforage from 'localforage';

localforage.config({
  name: 'localforage',
  storeName: 'keyvaluepairs'
});

const deleteOldStorage = async () => {
  try {
    await localforage.ready();

    const nrOfkeys = await localforage.length();
    if (nrOfkeys === 0) return;

    const rootValue = await localforage.getItem('persist:root');
    if (!rootValue) return;

    await localforage.removeItem('persist:root');
    await localforage.clear();
  } catch {}
};

deleteOldStorage();
