/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Translate } from 'react-localize-redux';
import { Container } from 'reactstrap';

import Helmet from '../components/common/Helmet';

export function PrivacyPolicyContent() {
  return (
    <>
      <h1>
        <Translate id="gdpr-terms.title" />
      </h1>
      <p className="mb-2">
        <Translate id="gdpr-terms.g1-1" />
      </p>
      <p className="mb-2">
        <Translate id="gdpr-terms.g1-2" />
      </p>
      <h3 className="mt-4 mb-2">
        <Translate id="gdpr-terms.h2" />
      </h3>
      <p className="mb-2">
        <Translate id="gdpr-terms.g2-1" />
      </p>
      <div className="d-flex felx-row">
        <span className="mr-5">
          <p className="mb-2">
            <Translate id="gdpr-terms.g2-2" />
          </p>
          <p className="mb-2">
            <Translate id="gdpr-terms.g2-3" />
          </p>
          <p className="mb-2">
            <Translate id="gdpr-terms.g2-4" />
          </p>
        </span>
        <span>
          <p className="mb-2">
            <Translate id="gdpr-terms.g2-5" />
          </p>
          <p className="mb-2">
            <Translate id="gdpr-terms.g2-6" />
          </p>
        </span>
      </div>

      <h3 className="mt-4 mb-2">
        <Translate id="gdpr-terms.h3" />
      </h3>
      <p className="mb-2">
        <Translate id="gdpr-terms.g3-1" />
      </p>
      <p className="mb-2">
        <Translate id="gdpr-terms.g3-2" />
      </p>
      <p className="mb-2">
        <Translate id="gdpr-terms.g3-3" />
      </p>
      <p className="mb-2">
        <Translate id="gdpr-terms.g3-4" />
      </p>
      <p className="mb-2">
        <Translate id="gdpr-terms.g3-5" />
      </p>
      <p className="mb-2">
        <Translate id="gdpr-terms.g3-6" />
      </p>
      <h3 className="mt-4 mb-2">
        <Translate id="gdpr-terms.h4" />
      </h3>
      <p className="mb-2">
        <Translate id="gdpr-terms.g4-1" />
      </p>
      <p className="mb-2">
        <Translate id="gdpr-terms.g4-2" />
      </p>
      <p className="mb-2">
        <Translate id="gdpr-terms.g4-3" />
      </p>
      <p className="mb-2">
        <Translate id="gdpr-terms.g4-4" />
      </p>
      <p className="mb-2">
        <Translate id="gdpr-terms.g4-5" />
      </p>
      <p className="mb-2">
        <Translate id="gdpr-terms.g4-6" />
      </p>
      <p className="mb-2">
        <Translate id="gdpr-terms.g4-7" />
      </p>
      <p className="mb-2">
        <Translate id="gdpr-terms.g4-8" />
      </p>
      <p className="mb-2">
        <Translate id="gdpr-terms.g4-9" />
      </p>
      <p className="mb-2">
        <Translate id="gdpr-terms.g4-10" />
      </p>
      <p className="mb-2">
        <Translate id="gdpr-terms.g4-11" />
      </p>
      <h3 className="mt-4 mb-2">
        <Translate id="gdpr-terms.h5" />
      </h3>
      <p className="mb-2">
        <Translate id="gdpr-terms.g5-1" />
      </p>
      <h3 className="mt-4 mb-2">
        <Translate id="gdpr-terms.h6" />
      </h3>
      <p className="mb-2">
        <Translate id="gdpr-terms.g6-1" />
      </p>
      <p className="mb-2">
        <Translate id="gdpr-terms.g6-2" />
      </p>
      <p className="mb-2">
        <Translate id="gdpr-terms.g6-3" />
      </p>
      <p className="mb-2">
        <Translate id="gdpr-terms.g6-4" />
      </p>
      <p className="mb-2">
        <Translate id="gdpr-terms.g6-5" />
      </p>
      <h3 className="mt-4 mb-2">
        <Translate id="gdpr-terms.h8" />
      </h3>
      <p className="mb-2">
        <Translate id="gdpr-terms.g8-1" />
      </p>
      <p className="mb-2">
        <Translate id="gdpr-terms.g8-2" />
      </p>
      <p className="mb-2">
        <Translate id="gdpr-terms.g8-3" />
      </p>
      <p className="mb-2">
        <Translate id="gdpr-terms.g8-4" />
      </p>
      <p className="mb-2">
        <Translate id="gdpr-terms.g8-5" />
      </p>
      <h3 className="mt-4 mb-2">
        <Translate id="gdpr-terms.h9" />
      </h3>
      <p className="mb-2">
        <Translate id="gdpr-terms.g9-1" />
      </p>
      <h3 className="mt-4 mb-2">
        <Translate id="gdpr-terms.h10" />
      </h3>
      <p className="mb-2">
        <Translate id="gdpr-terms.g10-1" />
      </p>
      <p className="mb-2">
        <Translate id="gdpr-terms.g10-2" />
      </p>
      <p className="mb-2">
        <Translate id="gdpr-terms.g10-3" />
      </p>
      <p className="mb-2">
        <Translate id="gdpr-terms.g10-4" />
      </p>
      <h3 className="mt-4 mb-2">
        <Translate id="gdpr-terms.h11" />
      </h3>
      <p className="mb-2">
        <Translate id="gdpr-terms.g11-1" />
      </p>
      <h3 className="mt-4 mb-2">
        <Translate id="gdpr-terms.h12" />
      </h3>
      <p className="mb-2">
        <Translate id="gdpr-terms.g12-1" />
      </p>
      <h3 className="mt-4 mb-2">
        <Translate id="gdpr-terms.h13" />
      </h3>
      <p className="mb-2">
        <Translate id="gdpr-terms.g13-1" />
      </p>
    </>
  );
}

function PrivacyPolicy() {
  return (
    <>
      <Helmet titleTranslationKey="gdpr-terms.title" descriptionTranslationKey="gdpr-terms.g1-1" />
      <Container>
        <PrivacyPolicyContent />
      </Container>
    </>
  );
}

export default PrivacyPolicy;
