import { useMutation, useQueryClient } from 'react-query';

import { del, post } from '../../http';
import { VoucherType } from '../vouchers/types';
import { QUERY_KEY_ORDER } from './api';
import { OrderType } from './types';

export const addVoucher = (orderId: OrderType['id'], voucherCode: VoucherType['voucherCode']) =>
  post<OrderType>(`v2/Orders/${orderId}/giftvoucher/${voucherCode}`, {});

export const deleteVoucher = (orderId: OrderType['id'], voucherCode: VoucherType['voucherCode']) =>
  del<OrderType>(`v2/Orders/${orderId}/giftvoucher/${voucherCode}`);

export const useAddVoucherCode = () => {
  const queryClient = useQueryClient();

  return useMutation<OrderType | null, unknown, VoucherType['voucherCode']>({
    mutationFn: voucherCode => {
      const order = queryClient.getQueryData<OrderType>(QUERY_KEY_ORDER);

      if (!order) {
        throw new Error('No order found');
      }

      return addVoucher(order.id, voucherCode);
    },
    onSuccess: order => {
      if (!order) {
        throw new Error('No order found');
      }

      queryClient.setQueryData<OrderType>(QUERY_KEY_ORDER, order);
    },
  });
};

export const useDeleteVoucherCode = () => {
  const queryClient = useQueryClient();

  return useMutation<OrderType | null, unknown, VoucherType['voucherCode']>({
    mutationFn: voucherCode => {
      const order = queryClient.getQueryData<OrderType>(QUERY_KEY_ORDER);

      if (!order) {
        throw new Error('No order found');
      }

      return deleteVoucher(order.id, voucherCode);
    },
    onSuccess: order => {
      if (!order) {
        throw new Error('No order found');
      }

      queryClient.setQueryData<OrderType>(QUERY_KEY_ORDER, order);
    },
  });
};
