import React, { FC } from 'react';
import { RegisterOptions, SubmitHandler, useForm } from 'react-hook-form';
import { FieldPath } from 'react-hook-form/dist/types/path';
import {
  Card,
  CardBody,
  CardHeader,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
} from 'reactstrap';

import { emailRegex } from '../../helpers/regex';
import useTranslate from '../../hooks/useTranslate';
import Button from '../Button/Button';

type Inputs = {
  email: string;
  firstName: string;
  lastName: string;
};

interface PayAsGuestFormProps {
  onCancel: () => void;
  onSubmit: SubmitHandler<Inputs>;
}

const GuestForm: FC<PayAsGuestFormProps> = props => {
  const { onCancel, onSubmit } = props;
  const { translate } = useTranslate();

  const {
    register: baseRegister,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<Inputs>({
    defaultValues: { email: '', firstName: '', lastName: '' },
    shouldUnregister: true,
  });

  // react-hook-form expect innerRef prop, but reactstrap provides only ref prop, so let's re-define register callback in our way
  const register = <TFieldName extends FieldPath<Inputs>>(
    name: TFieldName,
    options?: RegisterOptions<Inputs, TFieldName>
  ) => {
    const { ref: innerRef, ...rest } = baseRegister(name, options);

    return {
      innerRef,
      ...rest,
    };
  };

  return (
    <Card>
      <CardHeader className="pt-5 px-3 px-sm-5">
        <h1>{translate('guestForm.title')}</h1>
      </CardHeader>
      <CardBody className="px-3 pt-0 px-sm-5 mb-3">
        <p className="">{translate('guestForm.description')}</p>

        <Form onSubmit={handleSubmit(onSubmit)} noValidate>
          <FormGroup>
            <Label for="email">{translate('guestForm.inputs.email')}</Label>
            <Input
              id="email"
              placeholder="email@example.com"
              {...register('email', {
                required: String(translate('guestForm.errors.required')),
                pattern: {
                  value: emailRegex,
                  message: String(translate('guestForm.errors.email')),
                },
              })}
              invalid={!!errors.email}
            />
            {errors?.email && <FormFeedback>{errors.email.message}</FormFeedback>}
          </FormGroup>
          <FormGroup>
            <Label for="firstName">{translate('guestForm.inputs.firstName')}</Label>
            <Input
              id="firstName"
              {...register('firstName', {
                validate: value => !!value.trim() || String(translate('guestForm.errors.required')),
              })}
              invalid={!!errors.firstName}
            />
            {errors?.firstName && <FormFeedback>{errors.firstName.message}</FormFeedback>}
          </FormGroup>
          <FormGroup>
            <Label for="lastName">{translate('guestForm.inputs.lastName')}</Label>
            <Input
              id="lastName"
              {...register('lastName', {
                validate: value => !!value.trim() || String(translate('guestForm.errors.required')),
              })}
              invalid={!!errors.lastName}
            />
            {errors?.lastName && <FormFeedback>{errors.lastName.message}</FormFeedback>}
          </FormGroup>
          <div className="d-flex gap-3 justify-content-end">
            <Button tertiary onClick={onCancel} loading={isSubmitting}>
              {translate('guestForm.buttons.cancel')}
            </Button>
            <Button primary type="submit" loading={isSubmitting}>
              {translate('guestForm.buttons.submit')}
            </Button>
          </div>
        </Form>
      </CardBody>
    </Card>
  );
};

export default GuestForm;
