import React, { FC } from 'react';
import { PulseLoader } from 'react-spinners';
import { Button as BaseButton, ButtonProps as BaseButtonProps } from 'reactstrap';
import classNames from '../../helpers/classNames';
import styles from './Button.module.scss';

interface ButtonProps extends BaseButtonProps {
  primary?: boolean;
  tertiary?: boolean;
  outline?: boolean;
  rounded?: boolean;
  loading?: boolean;
}

const Button: FC<ButtonProps> = props => {
  const { primary, tertiary, outline, rounded, loading, className, children, ...rest } = props;

  return (
    <BaseButton
      color={primary ? 'success' : tertiary ? 'secondary' : 'light'}
      outline={outline}
      className={classNames(
        className,
        styles.button,
        primary && styles.primary,
        tertiary && styles.tertiary,
        outline && styles.outline,
        rounded && styles.rounded,
        loading && styles.loading
      )}
      {...rest}
    >
      <span className={styles.body}>{children}</span>
      {loading && (
        <span className={styles.loader}>
          <PulseLoader color="currentColor" size={6} />
        </span>
      )}
    </BaseButton>
  );
};

export default Button;
