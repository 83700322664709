import React from 'react';
import LinkComponent from '../common/LinkComponent';

function CoursesLinkListComponent({ club }) {
  return club.golfCourses?.map(c => (
    <div key={c.id}>
      <LinkComponent
        to={`booking/${club.golfClub.name}/${c.name}/`}
        className="text-success px-0 py-1"
      >
        {c.name}
      </LinkComponent>
    </div>
  ));
}

export default CoursesLinkListComponent;
