/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Translate } from 'react-localize-redux';
import { Container } from 'reactstrap';

import Helmet from '../components/common/Helmet';

export function TermsUseContent() {
  return (
    <>
      <h1>
        <Translate id="user-terms.title" />
      </h1>
      <p className="mb-2">
        <Translate id="user-terms.h1" />
      </p>
      <p className="mb-2">
        <Translate id="user-terms.g1-1" />
      </p>
      <p className="mb-2">
        <Translate id="user-terms.g1-2" />
      </p>
      <h3 className="mt-4 mb-2">
        <Translate id="user-terms.h2" />
      </h3>
      <p className="mb-2">
        <Translate id="user-terms.g2-1" />
      </p>
      <p className="mb-2">
        <Translate id="user-terms.g2-2" />
      </p>
      <p className="mb-2">
        <Translate id="user-terms.g2-3" />
      </p>
      <p className="mb-2">
        <Translate id="user-terms.g2-4" />
      </p>
      <p className="mb-2">
        <Translate id="user-terms.g2-5" />
      </p>
      <h3 className="mt-4 mb-2">
        <Translate id="user-terms.h3" />
      </h3>
      <p className="mb-2">
        <Translate id="user-terms.g3-1" />
      </p>
      <p className="mb-2">
        <Translate id="user-terms.g3-2" />
      </p>
      <p className="mb-2">
        <Translate id="user-terms.g3-3" />
      </p>
      <h3 className="mt-4 mb-2">
        <Translate id="user-terms.h4" />
      </h3>
      <p className="mb-2">
        <Translate id="user-terms.g4-1" />
      </p>
      <h3 className="mt-4 mb-2">
        <Translate id="user-terms.h5" />
      </h3>
      <p className="mb-2">
        <Translate id="user-terms.g5-1" />
      </p>
      <p className="mb-2">
        <Translate id="user-terms.g5-2" />
      </p>
      <h3 className="mt-4 mb-2">
        <Translate id="user-terms.h6" />
      </h3>
      <p className="mb-2">
        <Translate id="user-terms.g6-1" />
      </p>
      <p className="mb-2">
        <Translate id="user-terms.g6-2" />
      </p>
      <h3 className="mt-4 mb-2">
        <Translate id="user-terms.h7" />
      </h3>
      <p className="mb-2">
        <Translate id="user-terms.g7-1" />
      </p>
      <p className="mb-2">
        <Translate id="user-terms.g7-2" />
      </p>
      <h3 className="mt-4 mb-2">
        <Translate id="user-terms.h8" />
      </h3>
      <p className="mb-2">
        <Translate id="user-terms.g8-1" />
      </p>
      <p className="mb-2">
        <Translate id="user-terms.g8-2" />
      </p>
      <p className="mb-2">
        <Translate id="user-terms.g8-3" />
      </p>
      <h3 className="mt-4 mb-2">
        <Translate id="user-terms.h9" />
      </h3>
      <p className="mb-2">
        <Translate id="user-terms.g9-1" />
      </p>
      <p className="mb-2">
        <Translate id="user-terms.g9-2" />
      </p>
    </>
  );
}

function TermsUse() {
  return (
    <>
      <Helmet titleTranslationKey="user-terms.title" descriptionTranslationKey="user-terms.h1" />
      <Container>
        <TermsUseContent />
      </Container>
    </>
  );
}

export default TermsUse;
