import { useMutation } from 'react-query';

import * as Sentry from '@sentry/react';

import { parseJwt } from '../../../helpers/parseJwt';
import useStore from '../../../store/useStore';
import { post } from '../../http';
import {
  GuestLoginInputType,
  LoginInputType,
  LoginPayloadType,
  LoginResponseErrorType,
  LoginResponseType,
} from './login.types';

function isErrorResponseData(data: unknown): data is LoginResponseErrorType {
  return (data as LoginResponseErrorType)?.error !== undefined;
}

const logIn = async (input: LoginInputType) => {
  const data = await post<LoginResponseType>(
    /**
     * TODO
     * http.ts module rewrites Content-Type header for "Connect/token" request.
     * Is it necessary? Maybe we need to use another endpoint for authorization.
     */
    'connect/token',
    {},
    {
      client_id: 'app',
      client_secret: 'OnTeeRulesForever953214',
      grant_type: 'password',
      scope: 'api1',
      ...input,
    } satisfies LoginPayloadType
  );

  /**
   * TODO
   * The server responds with a status of 200 even during an error state.
   * This should be changed to 400 for error cases.
   * Requires a fix on the backend or moving to another API.
   */
  if (isErrorResponseData(data)) {
    throw new Error(data.error_description || data.error);
  }

  return data;
};

const useLogin = () =>
  useMutation('auth', logIn, {
    onSuccess: data => {
      if (!data) return;

      const { access_token: token } = data;
      const state = useStore.getState();

      state.login(token);
      state.openAuthModal(false);

      Sentry.setUser({ email: parseJwt(token)?.name });
    },
  });

export default useLogin;

const guestLogin = async (input: GuestLoginInputType) => {
  const data = await post<LoginResponseType>(`v2/Profiles/Email/${input.email}/GuestUserLogin`, {
    ...input,
  });
  return data;
};

export const useGuestLogin = () =>
  useMutation('auth', guestLogin, {
    onSuccess: data => {
      if (!data) return;

      const { access_token: token } = data;
      const state = useStore.getState();

      state.login(token);

      Sentry.setUser({ email: parseJwt(token)?.name });
    },
  });
