import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import LazyImg from '../LazyImg/LazyImg';
import styles from './TrendingAreaCard.module.scss';

interface TrendingAreaCardProps {
  name: string;
  imageUrl: string;
  url: string;
}

const TrendingAreaCard: FC<TrendingAreaCardProps> = props => {
  const { name, imageUrl, url } = props;
  return (
    <Link to={url} className={styles.container}>
      <h2 className={styles.title}>{name}</h2>
      <LazyImg src={imageUrl} alt={name} className={styles.image} />
    </Link>
  );
};

export default TrendingAreaCard;
