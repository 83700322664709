import React, { FC, HTMLAttributes } from 'react';

import useTranslate from '../../hooks/useTranslate';
import { IconArrowDown } from '../Icons/Icons';

const CartHint: FC<HTMLAttributes<HTMLDivElement>> = props => {
  const { translate } = useTranslate();

  return (
    <div {...props}>
      <div className="d-flex flex-column align-items-center mb-4">
        <p className="h2 text-white text-center" style={{ lineHeight: '1.5' }}>
          {translate('stickyCart.hint')}
        </p>
        <IconArrowDown size={50} color="#ffffff" />
      </div>
      <div
        className="position-fixed top-0 start-0 bg-black bg-opacity-75 h-100 w-100"
        style={{ zIndex: '-1' }}
      ></div>
    </div>
  );
};

export default CartHint;
