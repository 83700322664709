import { isFinite } from 'lodash';

export function formatCurrency(name: string, value: unknown, languageCode = 'en') {
  return isFinite(value)
    ? new Intl.NumberFormat(languageCode, {
        style: 'currency',
        currency: name,
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      }).format(Number(value))
    : '';
}

export function getCurrencySymbol(currencyCode: string, languageCode = 'en'): string {
  const formattedCurrency = formatCurrency(currencyCode, 0, languageCode);

  return formattedCurrency.replace(/\d/g, '').trim();
}
