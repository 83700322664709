import React from 'react';
import { useNavigate } from 'react-router-dom';
import { withLocalize } from 'react-localize-redux';
import { Col, Row } from 'reactstrap';
import TeaserItem2 from './TeaserItem2';
import useAppData from '../../../services/apis/golfCourses/api';

function TeaserList({ items }) {
  const navigate = useNavigate();
  const { data: golfCourses } = useAppData();

  const onClick = item => {
    if (item.golfClubId) {
      const course = golfCourses.find(c => c.golfClub.id === item.golfClubId);
      if (course) {
        navigate(`booking/${course.golfClub.name}/${course.golfCourse.name}`);
      }
    } else {
      navigate(`courses/${item.type}/${item.name}`);
    }
  };

  return (
    <Row>
      {items.map(i => (
        <Col>
          <TeaserItem2
            key={i.id}
            onClick={() => onClick(i)}
            title={i.name}
            images={i.images}
            country={i.country}
            district={i.district}
          />
        </Col>
      ))}
    </Row>
  );
}

export default withLocalize(TeaserList);
