import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Translate } from 'react-localize-redux';
import { useNavigate } from 'react-router';
import PulseLoader from 'react-spinners/PulseLoader';
import { Button, Card, CardBody, CardHeader, Col, Row } from 'reactstrap';

import { emailRegex } from '../helpers/regex';
import useTranslate from '../hooks/useTranslate';
import { ontee } from '../services';
import useStore from '../store/useStore';
import Input from './form/Input';

interface ForgotPasswordFormProps {
  showLogin: () => void | null;
}

const ForgotPasswordForm: FC<ForgotPasswordFormProps> = props => {
  const { showLogin } = props;

  const { translate, activeLanguage } = useTranslate();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [success, setSuccess] = useState(false);

  const authModalEmail = useStore(state => state.authModalEmail);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<{ email: string }>({
    defaultValues: { email: authModalEmail || '' },
  });

  const onSubmit = async (data: { email: string }) => {
    setIsLoading(true);
    setSuccess(false);
    setError(null);

    try {
      const { email } = data;
      await ontee.auth.forgotPassword(email);
      setSuccess(true);
    } catch (err: any) {
      setError(err?.message || err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Card>
      <CardHeader className="pt-5 px-3 px-sm-5">
        <h1>
          <Translate id="SignInContainerForgotPassword" />
        </h1>
      </CardHeader>
      <CardBody className="px-3 pt-0 px-sm-5">
        <p>
          <Translate id="ForgotPasswordContainerText" />
        </p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row className="mt-4">
            <Col>
              <Input
                name="email"
                label={String(translate('default.Email'))}
                autoComplete="email"
                register={register}
                rules={{ required: true, pattern: emailRegex }}
                error={errors.email && translate('errorInvalidEmail')}
              />
            </Col>
          </Row>

          <div className="text-end mt-1">
            {success && (
              <p className="text-success">
                <Translate id="ForgotPasswordContainerSuccess" />
              </p>
            )}
            {error && <div className="mb-1 text-danger">{error.message}</div>}
            <Button color="success" type="submit" disabled={isLoading}>
              {isLoading ? <PulseLoader size={10} color="#fff" /> : <Translate id="default.Send" />}
            </Button>

            <div className="text-end mt-1">
              <Button
                color="link"
                className="text-success btn-no-padding"
                onClick={() =>
                  showLogin ? showLogin() : navigate(`/${activeLanguage.code}/signin/`)
                }
              >
                <Translate id="default.SignIn" /> &raquo;
              </Button>
            </div>
          </div>
        </form>
      </CardBody>
    </Card>
  );
};

export default ForgotPasswordForm;
