import { useInfiniteQuery, useQuery } from 'react-query';

import { get } from '../../http';
import { InspirationResponseType, InspirationType } from './types';

const HOUR = 60 * 60 * 1000;
const DAY = 24 * HOUR;
const DEFAULT_LIMIT = 9;

type PaginationFilterType = Partial<Record<'limit' | 'page', number>>;
type InspirationFiltersType = Pick<
  InspirationType,
  'language' | 'releaseDate' | 'normalizedTitle'
> &
  PaginationFilterType;
type InspirationsFiltersType = Pick<InspirationType, 'language'> &
  Partial<Pick<InspirationType, 'category'>> &
  PaginationFilterType;

const getInspiration = ({ language, releaseDate, normalizedTitle }: InspirationFiltersType) =>
  get<InspirationType>(`v2/Inspirations/${language}/${releaseDate}/${normalizedTitle}`);

const getInspirations = (params?: InspirationsFiltersType) =>
  get<InspirationResponseType>(`v2/Inspirations/`, params);

export const useInspiration = (filters: InspirationFiltersType) =>
  useQuery({
    queryKey: ['inspiration', filters],
    queryFn: () => getInspiration(filters),
    retry: false,
    staleTime: HOUR,
    cacheTime: HOUR,
  });

export const useInspirations = (filters: InspirationsFiltersType, enabled = true) =>
  useQuery({
    queryKey: ['inspirations', filters],
    queryFn: () => getInspirations(filters),
    enabled,
  });

export const useInspirationsInfiniteQuery = (filters: InspirationsFiltersType) =>
  useInfiniteQuery({
    queryKey: ['inspirationsInfinite', filters],
    queryFn: ({ pageParam }) =>
      getInspirations({ limit: DEFAULT_LIMIT, page: Number(pageParam) || 1, ...filters }),
    getNextPageParam: (lastPage, allPages) =>
      lastPage?.length === (filters.limit || DEFAULT_LIMIT) ? allPages.length + 1 : undefined,
    staleTime: DAY,
    cacheTime: DAY,
  });
