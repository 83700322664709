import { MappedGolfCourseType } from '../golfCourses/types';
import {
  TeeTimeExtraProductPrebookType,
  TeeTimeRatePrebookMappedType,
  TeeTimeType,
} from '../teeTimes/types';
import { ProductStatus, ProductType } from './types';

function isProductType(arg: unknown): arg is ProductType {
  return arg !== null && typeof arg === 'object' && 'productCode' in arg;
}

export function isProductStatusConfirmed(product: unknown): boolean {
  if (isProductType(product)) {
    if (product.productCode === 'GC') return product.status === ProductStatus.Reserved;
    if (product.productCode === 'GCD') return product.status === ProductStatus.Delivered;
  }

  return false;
}

export function createTeeTimeOrderItem(
  course: MappedGolfCourseType,
  teeTime: TeeTimeType,
  rates: TeeTimeRatePrebookMappedType[],
  selectedExtraProducts: TeeTimeExtraProductPrebookType[],
  source?: 'TravelPlanner'
) {
  const {
    golfCourse: { id: golfCourseId },
  } = course;

  const nrPlayers = rates?.reduce((c, rate) => c + rate.nrPlayers, 0);
  const price = rates?.reduce((c, rate) => c + rate.nrPlayers * rate.price, 0);

  let extraProducts;
  if (selectedExtraProducts) {
    const items = selectedExtraProducts?.filter(xp => xp.count);
    if (items) {
      extraProducts = { id: items[0]?.id, items };
    }
  }

  return {
    golfCourseId,
    startTime: teeTime.dateTime,
    nrPlayers,
    rates: rates.filter(r => r.nrPlayers),
    extraProducts,
    price,
    origin: 'website',
    source,
  };
}
