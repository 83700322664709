import { calculateDistance } from '../../../helpers/distance';
import { MappedGolfCourseType } from '../golfCourses/types';

const GOLF_COURSE_SEARCH_RADIUS_KM = 200;
// todo create correct types
export type CityType = Pick<MappedGolfCourseType, 'latitude' | 'longitude'>;
const isGolfCourseNearCity = (golfCourse: MappedGolfCourseType, city: CityType) =>
  calculateDistance(city, golfCourse) <= GOLF_COURSE_SEARCH_RADIUS_KM;

export default isGolfCourseNearCity;
