import React, { FC } from 'react';
import ReactDatePicker, { CalendarContainer, registerLocale } from 'react-datepicker';
import type { DatePickerProps } from 'react-datepicker';
import type { CalendarContainerProps } from 'react-datepicker/dist/calendar_container';

import { isValid } from 'date-fns';

import { formatShortDate, formatShortDateRange } from '../../helpers/datetime';
import useTranslate from '../../hooks/useTranslate';
import styles from './DatePicker.module.scss';

export type { DatePickerProps } from 'react-datepicker';

registerLocale('sv', require('date-fns/locale/sv').default);
registerLocale('en', require('date-fns/locale/en-GB').default);
registerLocale('de', require('date-fns/locale/de').default);
registerLocale('no', require('date-fns/locale/nb').default);
registerLocale('dk', require('date-fns/locale/da').default);

const ThemeContainer: FC<CalendarContainerProps> = props => (
  <div className={styles.theme}>
    <CalendarContainer {...props} />
  </div>
);

const DatePicker: FC<DatePickerProps> = props => {
  const { value, ...rest } = props;
  const { activeLanguage } = useTranslate();
  let newValue = value;

  if (
    props.selectsRange &&
    props.startDate &&
    isValid(props.startDate) &&
    props.endDate &&
    isValid(props.endDate)
  ) {
    newValue = formatShortDateRange(props.startDate, props.endDate, activeLanguage.code);
  } else if (props.selected && isValid(props.selected)) {
    newValue = formatShortDate(props.selected, activeLanguage.code);
  }

  return (
    <ReactDatePicker
      locale={activeLanguage?.code}
      calendarContainer={ThemeContainer}
      popperClassName={styles.popper}
      value={newValue}
      {...rest}
    />
  );
};

export default DatePicker;
