import { useQuery, useQueryClient } from 'react-query';

import { get } from '../http';
import { QUERY_KEY_GOLFCOURSES } from './golfCourses/api';

const defaultImage =
  'https://letsgolfstorage.blob.core.windows.net/golfclubimages/Default_pic_GC_OT_small.jpg';

export const QUERY_KEY_STATISTICS = 'statistics';

const useStatistics = filter => {
  const queryClient = useQueryClient();
  const golfCourses = queryClient.getQueryData(QUERY_KEY_GOLFCOURSES);

  return useQuery([QUERY_KEY_STATISTICS, filter], () => getStatistics(golfCourses, filter), {
    enabled: golfCourses?.length > 0,
  });
};

export default useStatistics;

const getStatistics = async (golfCourses, filter) => {
  const dynamicData = await fetchClubDynamicData(filter);

  return dynamicData
    .map(d => ({
      ...d,
      data: d.data?.map(g => ({
        ...g,
        id: g.golfClubId,
        name: g.golfClub,
        type: 'club',
        images: extractGolfclubImage(g.golfClubId, golfCourses),
      })),
    }))
    .reduce((a, v) => ({ ...a, [keyFormat(v.description)]: v.data }), {});
};

/**
 * @param {{ country: 'es' | 'pt' | 'it'}} filter
 */
const fetchClubDynamicData = filter => get('v2/GolfClubs/DynamicData', filter);

const keyFormat = key =>
  key
    .toLowerCase()
    .split(' ')
    .map((word, idx) => (idx === 0 ? word : word.charAt(0).toUpperCase() + word.substring(1)))
    .join(' ')
    .replace(/ /g, '');

const extractGolfclubImage = (golfclubId, golfCourses) => {
  const golfcourse = golfCourses.find(g => g.golfClub.id === golfclubId);
  let image = golfcourse?.info.photos[0]?.smallAssetPath || golfcourse?.info.photos[0]?.assetPath;
  let webp;

  if (image) {
    const lastDot = image.lastIndexOf('.');
    const fileName = image.substr(0, lastDot);
    const fileExtension = image.substr(lastDot);
    image = `${fileName}${fileExtension}`;
    webp = `${fileName}.webp`;
  } else {
    image = defaultImage;
  }

  return { image, webp };
};
