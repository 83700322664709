import { ReactNode, useMemo } from 'react';

import useTranslate from '../../hooks/useTranslate';
import imageCancellationEn from './images/cancellation_en.png';
import imageCancellationSv from './images/cancellation_sv.png';
import imagePayLaterEn from './images/pay_later_en.png';
import imagePayLaterSv from './images/pay_later_sv.png';
import imagePriceGuaranteeEn from './images/price_guarantee_en.png';
import imagePriceGuaranteeSv from './images/price_guarantee_sv.png';

interface Benefit {
  img: string;
  alt: string;
  text: ReactNode;
  url?: string;
  tooltip?: ReactNode;
}

export const useBenefits = (): Benefit[] => {
  const { translate, activeLanguage } = useTranslate();
  const languageCode = activeLanguage.code;

  return useMemo(
    () => [
      {
        img: languageCode === 'sv' ? imageCancellationSv : imageCancellationEn,
        alt: 'cancellation',
        text: translate('benefits.cancellation'),
      },
      {
        url: `/${languageCode}/guaranteed/`,
        img: languageCode === 'sv' ? imagePriceGuaranteeSv : imagePriceGuaranteeEn,
        alt: 'price',
        text: translate('benefits.priceGuarantee.text'),
        tooltip: translate('benefits.priceGuarantee.tooltip'),
      },
      {
        img: languageCode === 'sv' ? imagePayLaterSv : imagePayLaterEn,
        alt: 'book',
        text: translate('benefits.payLater'),
      },
    ],
    [languageCode, translate]
  );
};
