import CartItem from './CartItem';

function CartItems({
  confirmed,
  cartItems,
  checkout,
  onItemUpdated,
  isGolfhaftetRequest,
  showRemoveFromCart,
  bookingRefs,
  isThirdParty,
  gitBookingExist,
  disabled,
}) {
  return (
    <div className="d-flex flex-column" style={{ overflowX: 'auto' }}>
      {cartItems?.map((ci, idx) => (
        <CartItem
          key={ci.id}
          idx={idx}
          item={ci}
          confirmed={confirmed}
          checkout={checkout}
          onItemUpdated={onItemUpdated}
          isGolfhaftetRequest={isGolfhaftetRequest}
          showRemoveFromCart={showRemoveFromCart}
          bookingRefs={bookingRefs}
          isThirdParty={isThirdParty}
          gitBookingExist={gitBookingExist}
          disabled={disabled}
        />
      ))}
    </div>
  );
}

export default CartItems;
