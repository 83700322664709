import React from 'react';
import { Translate } from 'react-localize-redux';
import { Col, Row } from 'reactstrap';

import {
  formatDateMedium,
  formatShortDateRange,
  formatXShortDateTime,
} from '../../helpers/datetime';
import { formatCurrency } from '../../helpers/formatCurrency';
import useTranslate from '../../hooks/useTranslate';

function CartItemSummary({ cartItems, isGolfhaftetRequest }) {
  const { activeLanguage, translate } = useTranslate();

  return cartItems?.map(item => {
    const getItemDate = () => {
      switch (item.type) {
        case 'booking':
          return formatXShortDateTime(item.startTime, activeLanguage.code);

        case 'accommodation':
          return formatShortDateRange(item.startTime, item.endTime, activeLanguage.code);

        case 'product':
          return translate('giftCard.validUntil', {
            date: formatDateMedium(item.validUntil, activeLanguage.code),
          });

        default:
          return null;
      }
    };

    return (
      <div key={item.id} className="mb-2">
        <Row>
          <Col xs="8">
            {item.type === 'product'
              ? `${translate('giftCard.label')} ${translate('giftCard.recipient', {
                  recipient: item.label,
                })}`
              : item.label}
            <br />
            {getItemDate()}
          </Col>
          <Col className="text-end">
            {formatCurrency(item.currency, item.price, activeLanguage.code)}
          </Col>
        </Row>
        {item.type === 'product' && !!item.shippingCost && (
          <Row>
            <Col>{translate('giftCard.shippingCost')}</Col>
            <Col className="text-end">
              {item.shippingCost} {item.currency}
            </Col>
          </Row>
        )}
        {isGolfhaftetRequest && (
          <Row>
            <Col>
              <Translate id="default.CartSummary.Fee" />
            </Col>
            <Col className="text-end">
              {formatCurrency(item.currency, 1.5 * item.amount, activeLanguage.code)}
            </Col>
          </Row>
        )}
      </div>
    );
  });
}

export default CartItemSummary;
