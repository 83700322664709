import React, { FC } from 'react';
import { Col, Row } from 'reactstrap';

import { formatCurrency } from '../../helpers/formatCurrency';
import useTranslate from '../../hooks/useTranslate';
import { OrderType } from '../../services/apis/order/types';
import Button from '../Button/Button';
import EmptyCartButton from '../common/buttons/EmptyCartButton';

interface CartSummaryShortPropsType {
  order: OrderType;
  goToCheckout: () => {};
  clearCart: () => {};
  booking?: boolean;
}

const CartSummaryShort: FC<CartSummaryShortPropsType> = props => {
  const { order, goToCheckout, clearCart } = props;
  const { translate, activeLanguage } = useTranslate();

  return (
    <div className="px-3">
      <Row>
        <Col>{translate('default.CancellationProtection')}</Col>
        <Col className="free-benefit text-end">{translate('default.Free')}</Col>
      </Row>
      {order.price && (
        <>
          <div className="orange-line orange-line-top-bottom" />
          <Row className="my-1 fw-bold">
            <Col>{translate('default.Total')}</Col>
            <Col className="text-end fs-5">
              {formatCurrency(order.currency.name, order.price, activeLanguage.code)}
            </Col>
          </Row>
          <div className="orange-line orange-line-top-bottom" />
        </>
      )}
      <div className="d-flex align-items-center justify-content-end gap-3 pt-1">
        <EmptyCartButton onClick={clearCart} />
        <Button onClick={() => goToCheckout()} primary>
          {translate('default.GoToCheckout')}
        </Button>
      </div>
    </div>
  );
};

export default CartSummaryShort;
