import { useCallback } from 'react';
import { matchPath, useLocation, useSearchParams } from 'react-router-dom';

export const ACCOMMODATION_PARAM = 'acc';
export const ACCOMMODATION_VALUE = '1';
const ACCOMMODATION_PATH = '/:locale/book-accommodation/';

export const useAccommodationFilter = (): [boolean, (value: boolean) => void] => {
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const [, setSearchParams] = useSearchParams();

  const isAccommodationFilter = searchParams.has(ACCOMMODATION_PARAM);
  const isHomePageAccommodation = !!matchPath(ACCOMMODATION_PATH, location.pathname);

  const setIsAccommodationFilter = useCallback(
    (value: boolean) => {
      setSearchParams(searchParams => {
        if (value) {
          searchParams.set(ACCOMMODATION_PARAM, ACCOMMODATION_VALUE);
        } else {
          searchParams.delete(ACCOMMODATION_PARAM);
        }

        return searchParams;
      });
    },
    [setSearchParams]
  );

  return [isAccommodationFilter || isHomePageAccommodation, setIsAccommodationFilter];
};
