import React, { useMemo } from 'react';
import { Translate } from 'react-localize-redux';

import { shuffle } from '../helpers/shuffle';
import useStatistics from '../services/apis/statistics';
import destinationSuggestions from '../services/destinationSuggestions';
import TeaserList from './lists/Teasers/TeaserList';

function TeaserCards() {
  const dynamicGolfClubData = useStatistics();
  const { latestBookingItems, newlyAddedGolfClubsItems, mostBookedGolfClubsLastMonthItems } =
    useMemo(
      () => ({
        latestBookingItems: dynamicGolfClubData.data?.latestBookings?.slice(0, 3),
        newlyAddedGolfClubsItems: dynamicGolfClubData.data?.newlyAddedGolfclubs?.slice(0, 3),
        mostBookedGolfClubsLastMonthItems:
          dynamicGolfClubData.data?.mostBookedGolfclubsLastMonth?.slice(0, 3),
      }),
      [dynamicGolfClubData.data]
    );
  const { popularItems, discoverNewItems } = useMemo(
    () => ({
      popularItems: shuffle(destinationSuggestions.popular).slice(0, 3),
      discoverNewItems: shuffle(destinationSuggestions.discoverNew).slice(0, 3),
    }),
    []
  );

  return (
    <div className="mt-5">
      <div className="mt-2">
        <h3 className="fw-bold mb-3">
          <Translate id="TeaserComponentPopulerTitle" />
        </h3>
        <TeaserList items={popularItems} />
      </div>

      {latestBookingItems && (
        <div className="mt-5">
          <h3 className="fw-bold mb-3">
            <Translate id="TeaserComponentRecentlyBooked" />
          </h3>
          <TeaserList items={latestBookingItems} />
        </div>
      )}

      <div className="mt-5">
        <h3 className="fw-bold mb-3">
          <Translate id="TeaserComponentDiscoverNewTitle" />
        </h3>
        <TeaserList items={discoverNewItems} />
      </div>

      {newlyAddedGolfClubsItems && (
        <div className="mt-5">
          <h3 className="fw-bold mb-3">
            <Translate id="TeaserComponentRecentlyAdded" />
          </h3>
          <TeaserList items={newlyAddedGolfClubsItems} />
        </div>
      )}

      {mostBookedGolfClubsLastMonthItems && (
        <div className="mt-5">
          <h3 className="fw-bold">
            <Translate id="TeaserComponentMostBookedLastMonth" />
          </h3>
          <TeaserList items={mostBookedGolfClubsLastMonthItems} />
        </div>
      )}
    </div>
  );
}

export default TeaserCards;
