import { useEffect, useRef } from 'react';

const currentOffsets = new WeakMap<HTMLElement, number>();

export function getCurrentOffset(element: HTMLElement): number | undefined {
  return currentOffsets.get(element);
}

export function calculateHidingHeaderOffset(
  headerHeight: number,
  scrollTop: number,
  lastScrollTop: number,
  currentOffset = 0
) {
  const delta = scrollTop - lastScrollTop;
  let newOffset = currentOffset - delta;

  if (newOffset < -headerHeight) {
    newOffset = -headerHeight;
  }
  if (newOffset > 0) {
    newOffset = 0;
  }

  return newOffset;
}

function useHidingHeader() {
  const headerRef = useRef<HTMLDivElement>(null);
  const lastScrollTop = useRef(window.scrollY);
  const currentOffset = useRef(0);
  const ticking = useRef(false);

  useEffect(() => {
    const header = headerRef.current;
    if (!header) return;

    const handleScroll = () => {
      const scrollTop = window.scrollY;

      let newCurrentOffset = calculateHidingHeaderOffset(
        header.offsetHeight,
        scrollTop,
        lastScrollTop.current,
        currentOffset.current
      );

      lastScrollTop.current = scrollTop;
      currentOffset.current = newCurrentOffset;

      if (!ticking.current) {
        window.requestAnimationFrame(() => {
          header.style.transform = `translateY(${currentOffset.current}px)`;
          currentOffsets.set(header, currentOffset.current);
          ticking.current = false;
        });
        ticking.current = true;
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      currentOffsets.delete(header);
    };
  }, []);

  return headerRef;
}
export default useHidingHeader;
