import { QueryClient, useQuery, useQueryClient } from 'react-query';

import pLimit from 'p-limit';

import { post } from '../../http';
import { GolfCourseType } from '../golfCourses/types';
import { BookingsAvailableResponseType } from './types';

const limitConcurrency = pLimit(1);

export const QUERY_KEY_TEETIMES = 'teetimes';

export const fetchAvailableTeeTimes = (golfCourseId: GolfCourseType['id'], date: string) =>
  post<BookingsAvailableResponseType>('v2/bookings/available', {
    golfCourseId,
    startTime: `${date} 00:00:00`,
    endTime: `${date} 23:59:59`,
  });

export const getTeeTimes = async (
  queryClient: QueryClient,
  golfCourseId: GolfCourseType['id'],
  date: string
): Promise<BookingsAvailableResponseType | null> => {
  const availability = queryClient.getQueryData<BookingsAvailableResponseType>([
    QUERY_KEY_TEETIMES,
    golfCourseId,
    date,
  ]);

  if (!availability) {
    const newAvailability = await limitConcurrency(() =>
      fetchAvailableTeeTimes(golfCourseId, date)
    );

    if (newAvailability) {
      return queryClient.setQueryData([QUERY_KEY_TEETIMES, golfCourseId, date], newAvailability);
    }
  }

  return availability || null;
};

const useTeeTimes = (golfCourseId: GolfCourseType['id'], date: string) => {
  const queryClient = useQueryClient();

  return useQuery(
    [QUERY_KEY_TEETIMES, golfCourseId, date],
    () => getTeeTimes(queryClient, golfCourseId, date),
    { keepPreviousData: true }
  );
};

export default useTeeTimes;
