import React, { FC, PropsWithChildren, ReactNode, useEffect, useState } from 'react';
import { Container } from 'reactstrap';

import styles from './UncontrolledSlider.module.scss';

export type UncontrolledSliderSlideType = {
  title: ReactNode;
  description: ReactNode;
  image: string;
  placeholder: string;
};

interface UncontrolledSliderProps {
  slides: UncontrolledSliderSlideType[];
}

const UncontrolledSlider: FC<PropsWithChildren<UncontrolledSliderProps>> = props => {
  const { slides, children } = props;
  const [activeIndex, setActiveIndex] = useState(0);

  const { image, placeholder, title, description } = slides.at(activeIndex) || {};

  useEffect(() => {
    if (slides.length < 2) return;

    const intervalId = window.setInterval(() => {
      setActiveIndex(index => (index + 1) % slides.length);
    }, 5000);

    return () => {
      clearInterval(intervalId);
    };
  }, [slides]);

  useEffect(() => {
    setActiveIndex(0);
  }, [slides]);

  return (
    <>
      {slides.map((slide, index) => (
        <link key={index} rel="preload" href={slide.image} as="image" />
      ))}
      <div className={styles.placeholder} style={{ backgroundImage: `url(${placeholder})` }}>
        <div
          className={styles.slider}
          style={{
            backgroundImage: `url(${image})`,
          }}
        >
          <Container className={styles.container}>
            <div className={styles.containerHeader}>
              <div className={styles.title}>{title}</div>
              <div className={styles.description}>{description}</div>
            </div>
            <div className={styles.containerFooter}>{children}</div>
          </Container>
        </div>
      </div>
    </>
  );
};

export default UncontrolledSlider;
