import React, { FC, InputHTMLAttributes, ReactNode, useId } from 'react';
import { RegisterOptions, UseFormRegister } from 'react-hook-form';
import { FormGroup, Label } from 'reactstrap';

interface CheckboxProps extends Pick<InputHTMLAttributes<HTMLInputElement>, 'disabled'> {
  error?: ReactNode;
  helperText?: ReactNode;
  label: ReactNode;
  name: string;
  register: UseFormRegister<any>;
  rules?: RegisterOptions;
}

const Checkbox: FC<CheckboxProps> = ({
  label,
  name,
  rules,
  disabled,
  helperText,
  error,
  register,
}) => {
  const id = useId();

  return (
    <div className="mb-3">
      <FormGroup check inline>
        <input
          id={id}
          type="checkbox"
          {...register(name, rules)}
          disabled={disabled}
          className="form-check-input mr-3"
        />
        <Label htmlFor={id} check>
          {label}
        </Label>
      </FormGroup>
      {helperText && <span className="small">{helperText}</span>}
      {error && <span className="small error d-flex mt-1">{error}</span>}
    </div>
  );
};
export default Checkbox;
