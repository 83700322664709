import React, { FC, HTMLAttributes, PropsWithChildren } from 'react';

import classNames from '../../helpers/classNames';
import styles from './Section.module.scss';

export const Section: FC<PropsWithChildren<HTMLAttributes<HTMLDivElement>>> = ({
  children,
  ...rest
}) => (
  <div {...rest} className={classNames(styles.section, rest.className)}>
    {children}
  </div>
);
