type JWTData = {
  // amr: string[];
  // aud: string;
  // auth_time: number;
  // client_id: string;
  // exp: number;
  // iat: number;
  // idp: string;
  // iss: string;
  // jti: string;
  name: string;
  // nbf: number;
  role: string;
  // scope: string[];
  sub: string;
};

export const parseJwt = (token: string): JWTData | null => {
  try {
    return JSON.parse(window.atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};
