import { post } from '../http';

const validateCard = async data => {
  try {
    const response = await validateGHCard(data);
    if (response.status === 'Error' || response.errorCode) {
      return response;
    }

    return null;
  } catch (error) {
    return error;
  }
};

export default validateCard;

const validateGHCard = data => post('v2/Partners/Visit/Validate', data, null, true);

// eslint-disable-next-line no-promise-executor-return
// const wait = ms => new Promise(resolve => setTimeout(resolve, ms));
// await wait(200);
// const res = Math.round(Math.random(), 0);
// return !!res;
