import { FC, FocusEventHandler, useEffect, useMemo, useRef, useState } from 'react';
import Autosuggest from 'react-autosuggest';
import { LocalizedElement } from 'react-localize-redux';

import Fuse from 'fuse.js';
import { CoordinateLongitudeLatitude } from 'haversine';
import { throttle } from 'lodash-es';
import ClearIcon from 'material-react-icons/Close';
import SearchIcon from 'material-react-icons/Search';

import classNames from '../helpers/classNames';
import { calculateDistance } from '../helpers/distance';
import useTranslate, { TranslateFunction } from '../hooks/useTranslate';
import { MappedCityType } from '../services/apis/cities/types';
import { MappedCountryType } from '../services/apis/countries/types';
import { MappedDistrictType } from '../services/apis/districts/types';
import { MappedGolfCourseType } from '../services/apis/golfCourses/types';
import useSearchData from '../services/apis/search/useSearchData';
import { latinizeReplace } from '../services/language/latinize';
import useStore from '../store/useStore';
import Button from './Button/Button';

interface SearchFieldProps {
  onSearchSelect: (
    results: MappedGolfCourseType | MappedCountryType | MappedDistrictType | MappedCityType | null
  ) => void;
  placeholder: string;
  onFocus?: FocusEventHandler<HTMLInputElement>;
  onClose?: () => void;
  onValueChange: (value: string) => void;
  disabled?: boolean;
  className?: string;
}

const SEARCH_LIMIT = 7;
const SCORE_THRESHOLD = 0.1;
const SCORE_THRESHOLD_MAX = 0.5;
const DISTANCE_THRESHOLD = 100; // in kilometers
const NEARBY_GOLF_COURSES_LIMIT = 5;
const NEARBY_CITIES_LIMIT = 2;

type SearchItemType = (
  | (MappedGolfCourseType & { type: 'golfCourse' })
  | (MappedCountryType & { type: 'country' })
  | (MappedDistrictType & { type: 'district' })
  | (MappedCityType & { type: 'city' })
) & {
  typeLabel: LocalizedElement;
  search: string;
};

const getCloseBySuggestions = (
  firstResult: Fuse.FuseResult<SearchItemType>,
  golfCourses: MappedGolfCourseType[],
  countries: MappedCountryType[],
  districts: MappedDistrictType[],
  cities: MappedCityType[],
  translate: TranslateFunction
) => {
  const { latitude, longitude, name } = firstResult.item;

  if (
    'district' in firstResult.item &&
    'country' in firstResult.item &&
    firstResult.item.type === 'golfCourse'
  ) {
    const districtId = firstResult.item.district.id;
    const countryId = firstResult.item.country.id;
    const district = districts.find(d => d.id === districtId)!;
    const country = countries.find(c => c.id === countryId)!;

    const districtSuggestion = {
      ...district,
      countryId,
      type: 'district' as const,
      typeLabel: translate('search.suggestion.type.district'),
    } satisfies SearchItemType;

    const countrySuggestion = {
      ...country,
      type: 'country' as const,
      typeLabel: translate('search.suggestion.type.country'),
    } satisfies SearchItemType;

    const latLng: CoordinateLongitudeLatitude = { latitude, longitude };
    const firstPartOfName = getFirstPartOfName(name);

    let golfCoursesNearby = filterNearbyGolfCourses(
      golfCourses,
      firstResult.item.id,
      firstPartOfName,
      latLng,
      translate
    );
    const citiesNearby = filterNearbyCities(cities, latLng, translate);

    return [...golfCoursesNearby, ...citiesNearby, districtSuggestion, countrySuggestion];
  }

  return [];
};

const getFirstPartOfName = (name: string) => {
  const nameParts = name.split(' ');
  if (nameParts[0].length >= 3) {
    return nameParts[0].toLowerCase();
  }
  return nameParts.slice(0, 2).join(' ').toLowerCase();
};

const filterNearbyGolfCourses = (
  golfCourses: MappedGolfCourseType[],
  excludeId: string | number,
  namePart: string,
  latLng: CoordinateLongitudeLatitude,
  translate: TranslateFunction
) => {
  let nearbyGolfCourses = golfCourses
    .filter(g => g.id !== excludeId && g.search.includes(namePart))
    .slice(0, NEARBY_GOLF_COURSES_LIMIT)
    .map(
      g =>
        ({
          ...g,
          type: 'golfCourse' as const,
          typeLabel: translate('search.suggestion.type.golfCourse'),
        } satisfies SearchItemType)
    );

  if (nearbyGolfCourses.length === 0) {
    nearbyGolfCourses = golfCourses
      .filter(
        g =>
          g.id !== excludeId &&
          calculateDistance({ latitude: g.latitude, longitude: g.longitude }, latLng) <
            DISTANCE_THRESHOLD
      )
      .slice(0, NEARBY_GOLF_COURSES_LIMIT)
      .map(
        g =>
          ({
            ...g,
            type: 'golfCourse' as const,
            typeLabel: translate('search.suggestion.type.golfCourse'),
          } satisfies SearchItemType)
      );
  }

  return nearbyGolfCourses;
};

const filterNearbyCities = (
  cities: MappedCityType[],
  latLng: CoordinateLongitudeLatitude,
  translate: TranslateFunction
) => {
  return cities
    .filter(
      city =>
        calculateDistance({ latitude: city.latitude, longitude: city.longitude }, latLng) <
        DISTANCE_THRESHOLD
    )
    .slice(0, NEARBY_CITIES_LIMIT)
    .map(
      city =>
        ({
          ...city,
          type: 'city' as const,
          typeLabel: translate('search.suggestion.type.city'),
        } satisfies SearchItemType)
    );
};

const fuse = new Fuse<SearchItemType>([], {
  keys: ['search'],
  includeScore: true,
});

const SearchField: FC<SearchFieldProps> = props => {
  const { className, disabled, onSearchSelect, placeholder, onFocus, onClose, onValueChange } =
    props;
  const {
    value: defaultValue = '',
    golfCourses = [],
    countries = [],
    districts = [],
    cities = [],
  } = useSearchData();
  const inputRef = useRef<HTMLInputElement>(null);
  const [value, setValue] = useState(defaultValue);
  const { translate } = useTranslate();
  const collection = useMemo(
    () => [
      ...golfCourses.map(item => ({
        ...item,
        type: 'golfCourse' as const,
        typeLabel: translate(`search.suggestion.type.golfCourse`),
      })),
      ...countries.map(item => ({
        ...item,
        type: 'country' as const,
        typeLabel: translate(`search.suggestion.type.country`),
      })),
      ...districts.map(item => ({
        ...item,
        type: 'district' as const,
        typeLabel: translate(`search.suggestion.type.district`),
      })),
      ...cities.map(item => ({
        ...item,
        type: 'city' as const,
        typeLabel: translate(`search.suggestion.type.city`),
      })),
    ],
    [golfCourses, countries, districts, cities, translate]
  );
  const [suggestions, setSuggestions] = useState<typeof collection>([]);

  const setSearchPhrase = useStore(state => state.setSearchPhrase);

  useEffect(() => {
    fuse.setCollection(collection);
  }, [collection]);

  useEffect(() => {
    onValueChange(value);
  }, [value, onValueChange]);

  const saveSearchPhrase = useMemo(
    () =>
      throttle(
        searchValue => {
          if (searchValue.length >= 4) {
            setSearchPhrase(searchValue);
          }
        },
        2000,
        { leading: false }
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <Autosuggest
      containerProps={{
        className: classNames('position-relative', className),
      }}
      suggestions={suggestions}
      onSuggestionsFetchRequested={data => {
        const searchValue = data.value?.trim().toLowerCase();
        if (searchValue.length < 2) return;

        saveSearchPhrase(searchValue);

        const pattern = searchValue.replace(/[^A-Za-z\d[\] ]/g, latinizeReplace);
        const results = fuse.search(pattern, { limit: SEARCH_LIMIT });
        const firstResult = results[0] || null;

        if (
          firstResult?.score &&
          firstResult.score < SCORE_THRESHOLD &&
          firstResult.item.type === 'golfCourse'
        ) {
          const suggest = getCloseBySuggestions(
            firstResult,
            golfCourses,
            countries,
            districts,
            cities,
            translate
          );
          setSuggestions([firstResult.item, ...suggest]);
          return;
        }

        const newSuggestions = results
          .filter(result => typeof result.score === 'number' && result.score < SCORE_THRESHOLD_MAX)
          .map(result => result.item);

        setSuggestions(newSuggestions);
      }}
      onSuggestionsClearRequested={() => {
        setSuggestions([]);
      }}
      onSuggestionSelected={(_, { suggestion }) => {
        onSearchSelect(suggestion);
      }}
      getSuggestionValue={suggestion => suggestion.name}
      renderSuggestion={suggestion => (
        <div className="d-flex gap-2 justify-content-between align-items-center">
          <span>{suggestion.name}</span>
          <small className="text-muted flex-shrink-0" style={{ fontWeight: 'normal' }}>
            {suggestion.typeLabel}
          </small>
        </div>
      )}
      renderSuggestionsContainer={({
        children,
        containerProps: { key, className, ...containerProps },
      }) => (
        <div {...containerProps} className={classNames('top-100', className)}>
          {children}
        </div>
      )}
      renderInputComponent={({
        // @ts-expect-error "Warning: A props object containing a "key" prop is being spread into JSX"
        key,
        ...inputProps
      }) => (
        <div
          className={classNames(
            'form-control d-flex gap-1 px-2 align-content-center align-items-center',
            disabled && 'opacity-50'
          )}
        >
          <SearchIcon className={'flex-shrink-0'} />
          <input {...inputProps} autoComplete="off" autoCorrect="off" spellCheck={false} />
          {!!inputProps.value && (
            <Button
              disabled={disabled}
              className={'text-black p-0 bg-transparent'}
              onClick={() => {
                setValue('');
                onClose?.();
                inputRef.current?.focus();
              }}
            >
              <ClearIcon className={'flex-shrink-0'} />
            </Button>
          )}
        </div>
      )}
      inputProps={{
        ref: inputRef,
        className: 'flex-fill d-inline-block text-truncate p-0',
        style: {
          minWidth: 0,
        },
        placeholder,
        value,
        onChange: (_, params) => {
          setValue(params.newValue);
        },
        onFocus,
      }}
    />
  );
};

export default SearchField;
