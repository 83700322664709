import { useCallback, useState } from 'react';

import { differenceInDays, parseISO } from 'date-fns';

import { formatISODate } from '../helpers/formatISODate';
import useStore from '../store/useStore';
import { useExitIntent } from './useExitIntent';

const DAYS_THRESHOLD = 30;

const useNewsletterSubscriptionPopup = () => {
  const [isVisible, setIsVisible] = useState(false);
  const { isLoggedIn, newsletterPopupLastShown, setNewsletterPopupLastShown } = useStore(
    state => state
  );

  const hide = useCallback(() => {
    setIsVisible(false);
  }, []);

  useExitIntent(
    useCallback(() => {
      if (isLoggedIn) return;

      const canShowPopup = () => {
        if (!newsletterPopupLastShown) return true;

        // Show popup once every N days
        return differenceInDays(new Date(), parseISO(newsletterPopupLastShown)) > DAYS_THRESHOLD;
      };

      if (canShowPopup()) {
        setIsVisible(true);
        setNewsletterPopupLastShown(formatISODate(new Date()));
      }
    }, [isLoggedIn, newsletterPopupLastShown, setNewsletterPopupLastShown])
  );

  return { isVisible, hide };
};

export default useNewsletterSubscriptionPopup;
