import { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { addDays } from 'date-fns';

import { formatISODate } from '../helpers/formatISODate';

export const START_DATE_PARAM = 'date';
export const END_DATE_PARAM = 'endDate';
const ADDITIONAL_DAYS = 2;

export const useDatesFilter = (): {
  startDate: Date;
  endDate: Date;
  setDates(startDate: Date, endData?: Date): void;
} => {
  const [searchParams, setSearchParams] = useSearchParams();

  const startDateParam = searchParams.get(START_DATE_PARAM);
  const endDateParam = searchParams.get(END_DATE_PARAM);
  const startDate = useMemo(
    () =>
      startDateParam
        ? new Date(`${startDateParam}T00:00:00Z`)
        : new Date(`${formatISODate(new Date())}T00:00:00Z`),
    [startDateParam]
  );
  const endDate = useMemo(
    () =>
      endDateParam ? new Date(`${endDateParam}T00:00:00Z`) : addDays(startDate, ADDITIONAL_DAYS),
    [endDateParam, startDate]
  );

  const setDates = useCallback(
    (newStartDate: Date, newEndDate?: Date) => {
      setSearchParams(searchParams => {
        const params = new URLSearchParams(searchParams);
        params.set(START_DATE_PARAM, formatISODate(newStartDate));

        if (newEndDate) {
          params.set(END_DATE_PARAM, formatISODate(newEndDate));
        } else {
          params.delete(END_DATE_PARAM);
        }

        return params;
      });
    },
    [setSearchParams]
  );

  return useMemo(
    () => ({
      startDate,
      endDate,
      setDates,
    }),
    [startDate, endDate, setDates]
  );
};
