import React, { Component } from 'react';

// TODO: Change to functional component
export default class FlexContainerComponent extends Component {
  render = () => (
    <div
      className={`FlexCenter d-flex flex-column ${this.props.className}`}
      style={{
        width: '100%',
        height: '100%',
        flexGrow: 1,
        ...this.props.style
      }}
    >
      {this.props.children}
    </div>
  );
}
