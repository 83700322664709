import React, { FC, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Container } from 'reactstrap';

import { groupBy, intersection, map, shuffle, without } from 'lodash-es';

import classNames from '../../helpers/classNames';
import useTranslate from '../../hooks/useTranslate';
import travelPlanBgImage from '../../pages/TravelPlanner/images/bg-image.jpg';
import styles from '../../pages/home/HomePage.module.scss';
import useGolfCourses from '../../services/apis/golfCourses/api';
import getGolfClubInfo from '../../services/apis/golfCourses/getGolfClubInfo';
import { MappedGolfCourseType } from '../../services/apis/golfCourses/types';
import { useInspirations } from '../../services/apis/inspirations/api';
import useStatistics from '../../services/apis/statistics';
import Button from '../Button/Button';
import DiscoverRegionBanner from '../DiscoverRegionBanner/DiscoverRegionBanner';
import GolfCoursesCards from '../GolfCoursesCards/GolfCoursesCards';
import InspirationCard from '../InspirationCard/InspirationCard';
import ScrollSnapSlider from '../ScrollSnapSlider/ScrollSnapSlider';
import TrendingAreaCard from '../TrendingAreaCard/TrendingAreaCard';
import { Header } from './Header';
import { Section } from './Section';

const STATISTICS_FILTER = {
  pt: { country: 'pt' },
  it: { country: 'it' },
};

const HomePageContentGolfCourses: FC = () => {
  const { translate, activeLanguage } = useTranslate();

  const { data: golfCourses } = useGolfCourses();

  const allIds = useMemo(() => golfCourses?.map(({ golfCourse: { id } }) => id), [golfCourses]);

  const allGolfClubIds = useMemo(
    () => golfCourses?.map(({ golfClub: { id } }) => id),
    [golfCourses]
  );

  const allDistrictIds = useMemo(
    () => golfCourses?.map(({ district: { id } }) => id),
    [golfCourses]
  );

  const { data: portugalStatisticsData } = useStatistics(STATISTICS_FILTER['pt']);
  const { data: italyStatisticsData } = useStatistics(STATISTICS_FILTER['it']);

  const spainGolfCourses = useMemo<MappedGolfCourseType[]>(() => {
    if (!golfCourses) {
      return [];
    }

    const selectedSpainIds = [
      1114, // https://www.ontee.com/en/booking/Lo%20Romero%20Golf/18-hole%20course
      3430, // https://www.ontee.com/en/booking/Villamartin/Tee%201
      3429, // https://www.ontee.com/en/booking/Las%20Ramblas/Tee%201
      1152, // https://www.ontee.com/en/booking/Real%20Club%20de%20Golf%20Campoamor%20Resort/18-hole%20course
      1142, // https://www.ontee.com/en/booking/Roda%20Golf%20Course/18-hole%20course
    ];
    const ids = intersection(selectedSpainIds, allIds);
    const spainIds = golfCourses
      .filter(({ country: { code } }) => code === 'ES')
      .map(({ golfCourse: { id } }) => id);

    if (ids.length < selectedSpainIds.length) {
      ids.push(
        ...shuffle(without(spainIds, ...selectedSpainIds)).slice(
          0,
          selectedSpainIds.length - ids.length
        )
      );
    }

    return [...ids].map(id => golfCourses.find(item => item.golfCourse.id === id)!);
  }, [golfCourses, allIds]);

  const portugalGolfCourses = useMemo<MappedGolfCourseType[]>(() => {
    if (!golfCourses) {
      return [];
    }

    const portugalGoldClubIds = (
      portugalStatisticsData?.latestBookings as Array<{ id: number }>
    )?.map(({ id }) => id);
    const ids = intersection(portugalGoldClubIds, allGolfClubIds);

    return [...ids].map(id => golfCourses.find(item => item.golfClub.id === id)!);
  }, [golfCourses, portugalStatisticsData, allGolfClubIds]);

  const italyGolfCourses = useMemo<MappedGolfCourseType[]>(() => {
    if (!golfCourses) {
      return [];
    }

    const italyGoldClubIds = (italyStatisticsData?.latestBookings as Array<{ id: number }>)?.map(
      ({ id }) => id
    );
    const ids = intersection(italyGoldClubIds, allGolfClubIds);

    return [...ids].map(id => golfCourses.find(item => item.golfClub.id === id)!);
  }, [golfCourses, italyStatisticsData, allGolfClubIds]);

  const selectedDistricts = useMemo<
    {
      name: string;
      image?: string;
    }[]
  >(() => {
    if (!golfCourses) {
      return [];
    }

    const selectedDistrictIds = [
      134, // Algarve - https://www.ontee.com/en/courses/districts/Algarve
      130, // Tuscany - https://www.ontee.com/en/courses/districts/Tuscany
      125, // Costa del Sol-Cádiz & Huelva - https://www.ontee.com/en/courses/districts/Costa%20del%20Sol-C%C3%A1diz%20&%20Huelva
      124, // Barcelona & Costa Brava - https://www.ontee.com/en/courses/districts/Barcelona%20&%20Costa%20Brava
      132, // Lisbon - https://www.ontee.com/en/courses/districts/Lisbon
    ];
    const ids = intersection(selectedDistrictIds, allDistrictIds);

    if (ids.length < selectedDistrictIds.length) {
      ids.push(
        ...shuffle(without(allDistrictIds, ...selectedDistrictIds)).slice(
          0,
          selectedDistrictIds.length - ids.length
        )
      );
    }

    const selectedGolfCourses = [...ids].map(
      id => golfCourses.find(item => item.district.id === id)!
    );
    const golfCoursesByDistricts = groupBy(selectedGolfCourses, item => item.district.id);

    return map(golfCoursesByDistricts, items => {
      const firstItem = items[0];

      return {
        name: firstItem.district.name,
        image: getGolfClubInfo(firstItem)?.image,
      };
    });
  }, [golfCourses, allDistrictIds]);

  const { data: inspirations } = useInspirations({ language: activeLanguage.code, limit: 7 });

  return (
    <>
      {/* Popular courses in Spain */}
      {!!spainGolfCourses?.length && (
        <>
          <DiscoverRegionBanner
            region="spain"
            url={`/${activeLanguage.code}/courses/countries/${spainGolfCourses[0].country.name}/`}
          />
          <Section>
            <Container>
              <Header>
                {translate('index.popularBooking', {
                  countryName: translate('regionNames.spain'),
                })}
              </Header>
              <GolfCoursesCards
                url={`/${activeLanguage.code}/courses/countries/${spainGolfCourses[0].country.name}/`}
                golfCourses={spainGolfCourses}
              />
            </Container>
          </Section>
        </>
      )}

      {/* Popular courses in Portugal */}
      {!!portugalGolfCourses?.length && (
        <>
          <DiscoverRegionBanner
            region="portugal"
            url={`/${activeLanguage.code}/courses/countries/${portugalGolfCourses[0].country.name}/`}
          />
          <Section>
            <Container>
              <Header>
                {translate('index.popularBooking', {
                  countryName: translate('regionNames.portugal'),
                })}
              </Header>
              <GolfCoursesCards
                url={`/${activeLanguage.code}/courses/countries/${portugalGolfCourses[0].country.name}/`}
                golfCourses={portugalGolfCourses}
              />
            </Container>
          </Section>
        </>
      )}

      {/* Popular courses in Italy */}
      {!!italyGolfCourses?.length && (
        <>
          <DiscoverRegionBanner
            region="italy"
            url={`/${activeLanguage.code}/courses/countries/${italyGolfCourses[0].country.name}/`}
          />
          <Section>
            <Container>
              <Header>
                {translate('index.popularBooking', {
                  countryName: translate('regionNames.italy'),
                })}
              </Header>
              <GolfCoursesCards
                url={`/${activeLanguage.code}/courses/countries/${italyGolfCourses[0].country.name}/`}
                golfCourses={italyGolfCourses}
              />
            </Container>
          </Section>
        </>
      )}

      {/* Travel Guide */}
      <Container>
        <Header>{translate('travel-planner.banner.header')}</Header>
      </Container>
      <div
        style={{
          backgroundImage: `url("${travelPlanBgImage}")`,
          height: '600px',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        }}
      >
        <Container className="d-flex flex-column align-items-center justify-content-center text-center h-100">
          <div className="display-5 text-uppercase fw-bold text-white">
            {translate('travel-planner.banner.title')}
          </div>
          <div className="text-center">
            <Button
              size="lg"
              primary
              className="d-inline-block mt-5 border-white"
              tag={Link}
              to={`/${activeLanguage.code}/planmytrip/`}
            >
              {String(translate('travel-planner.banner.cta'))}
            </Button>
          </div>
        </Container>
      </div>

      {/* Popular districts */}
      {!!selectedDistricts?.length && (
        <Section className={classNames(styles.backgroundColorWrapper, 'mt-0')}>
          <Container>
            <Header>{translate('trendingAreas.title')}</Header>
            <ScrollSnapSlider
              footer={
                <Link to={`/${activeLanguage.code}/courses/districts/`}>
                  {translate('trendingAreas.showAll')}
                </Link>
              }
            >
              {selectedDistricts.map(({ name, image }) => (
                <TrendingAreaCard
                  key={name}
                  name={name}
                  imageUrl={image ?? ''}
                  url={`/${activeLanguage.code}/courses/districts/${name}/`}
                />
              ))}
            </ScrollSnapSlider>
          </Container>
        </Section>
      )}

      {/* Inspirations */}
      {!!inspirations?.length && (
        <Section>
          <Container>
            <Header>{translate('inspiration.title')}</Header>
            <ScrollSnapSlider
              footer={
                <Link to={`/${activeLanguage.code}/inspirations/`}>
                  {translate('inspiration.showAll')}
                </Link>
              }
            >
              {inspirations.map(inspiration => (
                <InspirationCard key={inspiration.id} inspiration={inspiration} />
              ))}
            </ScrollSnapSlider>
          </Container>
        </Section>
      )}
    </>
  );
};

export default HomePageContentGolfCourses;
