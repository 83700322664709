import { useQuery, useQueryClient } from 'react-query';

import { get } from '../../http';
import { usePreBook } from '../order/api';
import {
  AccommodationItemAddonType,
  AccommodationItemPackageType,
  AccommodationItemType,
  AccommodationType,
  AccommodationsAvailabilityResponseType,
  AccommodationsResponseType,
} from './types';

const ONE_MINUTE = 60_000;
const FIVE_MINUTES = 5 * ONE_MINUTE;

export const QUERY_KEY_ACCOMMODATION_ITEMS = 'accommodationItems';

const getAccommodations = () => get<AccommodationsResponseType>('v2/Accommodations');

export const useAccommodation = (accommodationId: AccommodationType['id']) =>
  useQuery(
    ['accommodation', accommodationId],
    async () => {
      const accommodations = await getAccommodations();

      return accommodations?.find(item => item.id === accommodationId);
    },
    {
      enabled: !!accommodationId,
    }
  );

export const getAccommodationsAvailable = (
  accommodationId: number,
  checkinDate: string,
  checkoutDate: string
) =>
  get<AccommodationsAvailabilityResponseType>('v2/Accommodations/Available', {
    accommodationId,
    checkinDate,
    checkoutDate,
  });

export const useAccommodationItems = (
  accommodationId: AccommodationType['id'],
  checkinDate: string,
  checkoutDate: string
) =>
  useQuery(
    [QUERY_KEY_ACCOMMODATION_ITEMS, accommodationId, checkinDate, checkoutDate],
    async () => {
      const data = await getAccommodationsAvailable(accommodationId, checkinDate, checkoutDate);

      data?.availability.sort((a, b) => {
        if (a.pricing.sellPrice !== b.pricing.sellPrice)
          return b.pricing.sellPrice - a.pricing.sellPrice;

        // TODO add correct locales
        return a.name.localeCompare(b.name, undefined, {
          numeric: true,
          sensitivity: 'base',
        });
      });

      return data?.availability;
    },
    {
      enabled: !!accommodationId && !!checkinDate && !!checkoutDate,
      cacheTime: FIVE_MINUTES,
      staleTime: ONE_MINUTE,
      keepPreviousData: true,
    }
  );

export const useAccommodationItemPrebook = () => {
  const prebookQuery = usePreBook();
  const queryClient = useQueryClient();

  return {
    mutateAsync: (params: {
      accommodationId: AccommodationType['id'];
      checkinDate: string;
      checkoutDate: string;
      origin: string;
      accommodations: Array<{
        id: AccommodationItemType['id'];
        numberOfGuests: number;
        addons: Array<{
          id: AccommodationItemAddonType['id'];
          name: AccommodationItemAddonType['name'];
          itemCount: number;
        }>;
        packages: Array<{
          id: AccommodationItemPackageType['id'];
          name: AccommodationItemPackageType['name'];
          itemCount: number;
        }>;
      }>;
    }) =>
      prebookQuery
        .mutateAsync(
          // @ts-expect-error incorrect typings
          {
            accommodation: params,
          }
        )
        .finally(() => {
          queryClient.invalidateQueries([QUERY_KEY_ACCOMMODATION_ITEMS]);
        }),
    isLoading: prebookQuery.isLoading,
    error: prebookQuery.error,
    reset: prebookQuery.reset,
  };
};
