import React, { FC } from 'react';
import PulseLoader from 'react-spinners/PulseLoader';

interface CenteredLoaderPropsType {
  message?: string;
}

const CenteredLoader: FC<CenteredLoaderPropsType> = props => {
  const { message } = props;

  return (
    <div className="my-5 py-5 text-center">
      <PulseLoader color="rgb(124, 163, 36)" loading />
      {message && <h5 className="my-5">{message}</h5>}
    </div>
  );
};

export default CenteredLoader;
