// prettier-ignore
import 'core-js/stable';
import 'regenerator-runtime/runtime';

import './wdyr';
import React, { Suspense, useEffect } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './services/sentry';
import './services/gtm';
import { ErrorBoundary } from '@sentry/react';
import { LocalizeProvider } from 'react-localize-redux';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import './services/deleteOldStorage';
import { languages, onMissingTranslation } from './services/i18n';
import { language } from './services/language';
import InitialLanguage from './components/InitialLanguage';
import queryClient, { prefetchAppData } from './services/reactQuery';
import './assets/styles/scss/style.scss';
import App from './App';

const PrefetchAppData = () => {
  useEffect(() => {
    prefetchAppData(language);
  }, []);

  return null
}

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <LocalizeProvider
    // todo replace react-localize-redux with something new and more stable
    initialize={{
      languages,
      options: {
        renderToStaticMarkup,
        onMissingTranslation,
        defaultLanguage: 'en',
        // todo prevent unsafe html usage
        renderInnerHtml: true,
      },
    }}
  >
    {/* Initialize data in component to minimize re-rendering */}
    <InitialLanguage language={language} />
    <BrowserRouter>
      <Suspense fallback={<p> Loading...</p>}>
        <QueryClientProvider client={queryClient}>
          <PrefetchAppData />
          <ErrorBoundary fallback={<p />} showDialog>
            <App />
          </ErrorBoundary>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </Suspense>
    </BrowserRouter>
  </LocalizeProvider>
);

// eslint-disable-next-line no-console
console.log(
  `%c Version: ${process.env.REACT_APP_VERSION} in ${process.env.NODE_ENV} / ${process.env.REACT_APP_ENVIRONMENT}`,
  'color: rgb(124, 163, 36)'
);
