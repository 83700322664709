import { useMutation, useQuery, useQueryClient } from 'react-query';

import { differenceInHours, differenceInSeconds } from 'date-fns';

import { parseDateInUTC } from '../../../helpers/datetime';
import { del, get } from '../../http';

export const QUERY_KEY_BOOKINGS = ['profile', 'bookings'];

export const useBookings = options =>
  useQuery(
    QUERY_KEY_BOOKINGS,
    async () => {
      const [activeOrders, inActiveOrders] = await Promise.all([
        getOrders('upcoming'),
        getOrders('history'),
      ]);

      const allOrders = activeOrders.concat(inActiveOrders);
      const bookings = convertOrdersToBookings(allOrders);
      const splittedBookings = sortAndSplitBookings(bookings);

      return splittedBookings;
    },
    options
  );

export const useDeleteBooking = () => {
  const queryClient = useQueryClient();
  return useMutation(cancelBooking, {
    onSuccess: booking => {
      queryClient.setQueryData(QUERY_KEY_BOOKINGS, bookings => ({
        ...bookings,
        upcoming: [
          ...bookings.upcoming.map(b => (b.id === booking.id ? { ...b, status: 6 } : { ...b })),
        ],
      }));
    },
  });
};

const getOrders = type =>
  get('v2/orders/me', { limit: 20, offset: 0, active: type === 'upcoming' });

const cancelBooking = booking =>
  booking.type === 'teetime'
    ? cancelTeetimeBooking(booking.id)
    : cancelAccommodationBooking(booking.id);
const cancelTeetimeBooking = id => del(`v2/Bookings/${id}`);
const cancelAccommodationBooking = id => del(`v2/Accommodations/${id}`);

const convertOrdersToBookings = orders =>
  orders
    .map(o => convertOrderToBookings(o))
    .flat()
    .filter(b => b);

const convertOrderToBookings = o => {
  const now = new Date();

  return o.orderItems.map(oi => {
    const bkn = oi.booking || oi.accommodationBooking;
    return convertBooking(o, oi, bkn, now);
  });
};

const convertBooking = (o, oi, booking, now) => {
  const startTime = booking?.startTime || booking?.checkinDate;
  if (![2, 6].includes(booking?.status)) return null; // Only reserved and canceled

  let extraProducts;
  try {
    extraProducts = JSON.parse(
      booking.extraProducts.replace(/"([^"]+)":/g, function ($0, $1) {
        return '"' + $1.toLowerCase() + '":';
      })
    )?.items;
  } catch {}

  return {
    type: booking?.accommodationId ? 'accommodation' : 'teetime',
    id: booking?.id,
    startTime,
    checkinDate: booking?.checkinDate,
    checkoutDate: booking?.checkoutDate,
    canCancelUntil: booking?.canCancelUntil,
    // Start time instead of canCancelUntil is used for canCancel. That way user can try to cancel and get error message if not possible
    canCancel: differenceInSeconds(parseDateInUTC(startTime), now) > 0,
    log: [parseDateInUTC(startTime), now, differenceInSeconds(parseDateInUTC(startTime), now)],
    golfClub: booking?.golfClub || booking?.accommodationName,
    golfCourse: booking?.golfCourse || booking?.accommodationType,
    nrPlayers: booking?.nrPlayers || booking?.numberOfGuests,
    status: booking?.status,
    price: booking?.price,
    orderRef: booking?.orderRef,
    products: oi.itemInfo?.lines,
    isEditable: booking?.isEditable,
    pendingEdit: booking?.pendingEdit,
    extraProducts,
    fromGolfhaftet: booking?.fromGolfhaftet,
    order: {
      id: o.id,
      status: o.status,
      price: o.price,
      currency: o.currency?.name,
      invoiceUrl: o.payments[0]?.invoiceUrl,
      payments: o.payments?.map(p => ({
        id: p.id,
        date: p.paymentDate,
        status: p.paymentStatus,
        paymentMethod: p.paymentMethod,
        transactionAmount: p.transactionAmount,
        transactionCurrency: p.transactionCurrency,
        paidAmount: p.paidAmount,
        dueDate: p.dueDate,
      })),
      bookings: o.orderItems
        .filter(_oi => _oi.booking || _oi.accommodationBooking)
        .map(_oi =>
          _oi.booking?.id
            ? {
                id: _oi.booking.id,
                startTime: _oi.booking.startTime,
                golfClub: _oi.booking.golfClub,
                golfCourse: _oi.booking.golfCourse,
                nrPlayers: _oi.booking.nrPlayers,
                status: _oi.booking.status,
                fromGolfhaftet: _oi.booking.fromGolfhaftet,
                type: 'teetime',
              }
            : {
                id: _oi.accommodationBooking.id,
                checkinDate: _oi.accommodationBooking.checkinDate,
                checkoutDate: _oi.accommodationBooking.checkoutDate,
                golfClub: _oi.accommodationBooking.accommodationName,
                golfCourse: _oi.accommodationBooking.accommodationType,
                nrPlayers: _oi.accommodationBooking.numberOfGuests,
                status: _oi.accommodationBooking?.status,
                type: 'accommodation',
              }
        )
        .filter(b => [2, 6, 10].includes(b.status)), // Reserved, Canceled, Error
    },
  };
};

const sortAndSplitBookings = bookings => {
  const now = new Date();
  const bks = { upcoming: [], history: [] };

  bookings.forEach(b => {
    if (differenceInHours(parseDateInUTC(b.startTime), now) >= -4) {
      bks.upcoming.push(b);
    } else {
      bks.history.push(b);
    }
  });

  bks.upcoming.sort((a, b) => parseDateInUTC(a.startTime) - parseDateInUTC(b.startTime));
  bks.history.sort((a, b) => parseDateInUTC(b.startTime) - parseDateInUTC(a.startTime));

  return bks;
};
