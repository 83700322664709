import { FC, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop: FC = () => {
  const { state, pathname } = useLocation();
  const preventScrollToTopRef = useRef(false);
  preventScrollToTopRef.current = state?.preventScrollToTop;

  useEffect(() => {
    if (preventScrollToTopRef.current) return;

    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

export default ScrollToTop;
