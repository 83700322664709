import React, { FC, PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';

import classNames from '../../helpers/classNames';
import useTranslate from '../../hooks/useTranslate';

interface LinkComponentProps {
  to: string;
  className?: string;
}

const LinkComponent: FC<PropsWithChildren<LinkComponentProps>> = props => {
  const { to, children, className = '' } = props;
  const { activeLanguage } = useTranslate();

  return (
    <Link to={`/${activeLanguage.code}/${to}`} className={classNames('nav-link', className)}>
      {children}
    </Link>
  );
};

export default LinkComponent;
