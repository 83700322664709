import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

// NODE_ENV has value production as soon as a production build is made
// REACT_APP_ENVIRONMENT holds the actual environment valud
const inProduction = process.env.NODE_ENV === 'production';

if (inProduction) {
  Sentry.init({
    dsn: 'https://75e6ece65a1448a18e369612240ac906@o486889.ingest.sentry.io/6739964',
    integrations: [new BrowserTracing()],
    tracesSampleRate: 0.1,
    environment: process.env.REACT_APP_ENVIRONMENT,
    release: process.env.REACT_APP_VERSION
  });
}

// eslint-disable-next-line import/prefer-default-export
export const sentryErrorHttp = (errorMessage, { status, url, method, body }) => {
  Sentry.withScope(scope => {
    scope.setLevel(status < 500 ? 'warning' : 'error');
    scope.setContext('http', {
      status,
      url,
      method,
      body
    });

    Sentry.captureException(new Error(errorMessage));
  });
};

export const sentryMessage = (errorMessage, data) => {
  Sentry.withScope(scope => {
    scope.setLevel('error');
    scope.setContext('data', data);

    Sentry.captureMessage(errorMessage);
  });
};
