import React, { FC, ReactNode } from 'react';
import { Translate } from 'react-localize-redux';

import useBreakpoints from '../../hooks/useBreakpoints';
import Button from '../Button/Button';
import { IconCamper, IconGolfer } from '../Icons/Icons';

export type SelectedOptionType = 'tee_times' | 'accommodation';

interface SearchBarSwitcherProps {
  theme?: 'light' | 'dark';
  hideLabels?: boolean;
  selectedOption?: SelectedOptionType;
  onChange?: (value: SelectedOptionType) => void;
  disabled?: boolean;
}

const SearchBarSwitcher: FC<SearchBarSwitcherProps> = props => {
  const { hideLabels, selectedOption, onChange, theme = 'light', disabled } = props;
  const isBreakPoints = useBreakpoints();

  return (
    <div className="d-flex flex-row gap-2">
      {(
        [
          {
            label: <Translate id="SearchBarSwitcherLabel.TeeTimes" />,
            icon: <IconGolfer />,
            value: 'tee_times',
          },
          {
            label: <Translate id="SearchBarSwitcherLabel.Accommodation" />,
            icon: <IconCamper />,
            value: 'accommodation',
          },
        ] as {
          label: ReactNode;
          icon: ReactNode;
          value: SelectedOptionType;
        }[]
      ).map(({ label, icon, value }) => (
        <Button
          key={value}
          primary={selectedOption === value}
          outline={selectedOption !== value && theme === 'dark'}
          onClick={() => onChange?.(value)}
          disabled={disabled}
        >
          {icon}
          {!hideLabels && (!isBreakPoints.lg ? <small>{label}</small> : label)}
        </Button>
      ))}
    </div>
  );
};

export default SearchBarSwitcher;
