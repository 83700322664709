import { useRef } from 'react';

const usePrevious = <T>(value: T): T | undefined => {
  const refActual = useRef(value);
  const refPrev = useRef<T | undefined>(undefined);

  if (value !== refActual.current) {
    refPrev.current = refActual.current;
    refActual.current = value;
  }

  return refPrev.current;
};

export default usePrevious;
