import React from 'react';
import { CircleLoader } from 'react-spinners';
import FlexCenterComponent from './FlexCenterComponent';

function LoadingScreenComponent({ text, show }) {
  const showStyle = show
    ? {
        visibility: 'visible',
        opacity: 1,
        transition: 'visibility 0.2s, opacity 0.2s ease-out'
      }
    : {
        visibility: 'hidden',
        opacity: 0,
        transition: 'visibility 0.5s, opacity 0.5s ease-in'
      };
  const noText = text
    ? {
        position: 'fixed',
        zIndex: 9999
      }
    : {};

  return (
    <div
      style={{
        backgroundColor: '#fafafa',
        height: '100%',
        width: '100%',
        ...showStyle,
        ...noText
      }}
    >
      <FlexCenterComponent>
        <h3>{(typeof text !== 'undefined' && text) || ''}</h3>
        <CircleLoader color="rgb(124, 163, 36)" loading={show} />
      </FlexCenterComponent>
    </div>
  );
}

export default LoadingScreenComponent;
