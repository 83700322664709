import React, { FC } from 'react';
import { Container } from 'reactstrap';

import useTranslate from '../../hooks/useTranslate';
import styles from './DownloadApp.module.scss';
import ImageBookingDetails from './images/booking_detailes.png';
import ImageReserveTeeTime from './images/reserve_tee_time.png';

const androidLanguage: Record<string, string> = {
  sv: 'sv',
  no: 'no',
  dk: 'da',
  de: 'de',
};

const appleLanguage: Record<string, string> = {
  sv: 'sv-se',
  no: 'no-no',
  dk: 'da-dk',
  de: 'de-de',
};

type DownloadAppProps = {
  isAccommodation?: boolean;
};

const DownloadApp: FC<DownloadAppProps> = props => {
  const { isAccommodation } = props;
  const { translate, activeLanguage } = useTranslate();

  // https://play.google.com/intl/en_us/badges/
  const imageAndroidButton = `https://play.google.com/intl/${
    androidLanguage[activeLanguage.code] ? 'sv_se' : 'en_gb'
  }/badges/images/generic/${androidLanguage[activeLanguage.code] || 'en'}_badge_web_generic.png`;

  // https://tools.applemediaservices.com/app/1291310120
  const imageAppleButton = `https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/${
    appleLanguage[activeLanguage.code] || 'en-us'
  }`;

  return (
    <div className={styles.wrapper}>
      <Container className={styles.container}>
        <div className={styles.textWrapper}>
          <h1 className={styles.title}>{translate('downloadApp.title')}</h1>
          <p>
            {isAccommodation
              ? translate('downloadApp.text-accommodation')
              : translate('downloadApp.text')}
          </p>
          <ul>
            <li>{translate('downloadApp.points.1')}</li>
            <li>{translate('downloadApp.points.2')}</li>
            <li>{translate('downloadApp.points.3')}</li>
          </ul>
          <div className={styles.buttonsWrapper}>
            <a
              href="https://itunes.apple.com/app/ontee/id1291310120"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={imageAppleButton}
                alt="AppleButton"
                width={activeLanguage.code === 'en' ? '200' : '230'}
              />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.ontee.onteeApp"
              target="_blank"
              rel="noreferrer"
            >
              <img src={imageAndroidButton} alt="AndroidButton" width="260" />
            </a>
          </div>
        </div>
        <div className={styles.imagesWrapper}>
          <div className={styles.imagesWrapperItem}>
            <img src={ImageBookingDetails} alt="Booking detailes" />
          </div>
          <div className={styles.imagesWrapperItem}>
            <img src={ImageReserveTeeTime} alt="Reserve tee times" />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default DownloadApp;
