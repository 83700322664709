import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { post } from '../../http';
import { sentryMessage } from '../../sentry';

const AFFILIATE_PARAM_NAME = 'affiliates';
const GOOGLE_RWG_TOKEN = 'rwg_token';
const GOOGLE_MERCHANT_ID = 'merchant_id';

interface AffiliateData extends Record<string, unknown> {
  affiliate?: string | null;
  rwgToken?: string | null;
  merchantId?: string | null;
  url?: string | null;
}

const createAffiliateCookie = (affiliateData: AffiliateData) =>
  post<void>(`v2/affiliate`, affiliateData);

export const useAffiliateInit = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const affiliate = searchParams.get(AFFILIATE_PARAM_NAME);
    const rwgToken = searchParams.get(GOOGLE_RWG_TOKEN);
    const merchantId = searchParams.get(GOOGLE_MERCHANT_ID);

    if (affiliate || rwgToken) {
      const url = window.frames.top?.document.referrer || null;
      createAffiliateCookie({ affiliate, rwgToken, merchantId, url })
        .then(() => {
          const params = [AFFILIATE_PARAM_NAME, GOOGLE_RWG_TOKEN, GOOGLE_MERCHANT_ID];
          const newSearchParams = new URLSearchParams(searchParams);
          params.forEach(param => newSearchParams.delete(param));
          setSearchParams(newSearchParams);
        })
        .catch(error =>
          sentryMessage('Something went wrong with affiliate cookie creation', {
            error,
            affiliate,
          })
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
