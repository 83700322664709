import React from 'react';
import { Button } from 'reactstrap';

import useTranslate from '../../../hooks/useTranslate';

function EmptyCartButton({ onClick }) {
  const { translate } = useTranslate();

  return (
    <Button className="text-danger d-flex align-items-center" color="link" onClick={onClick}>
      <small>{translate('default.EmptyCart')}</small>
    </Button>
  );
}

export default EmptyCartButton;
