import { MappedGolfClubInfoType, MappedGolfCourseType } from './types';

function getGolfClubInfo(obj: MappedGolfCourseType): MappedGolfClubInfoType | null {
  if (obj && obj.info && Object.keys(obj.info).length > 0) {
    return obj.info as MappedGolfClubInfoType;
  }

  return null;
}

export default getGolfClubInfo;
