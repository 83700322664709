import { MappedGolfCourseType } from '../services/apis/golfCourses/types';
import { Slice } from './types';

export interface CreateUISlice {
  activeGolfCourseId: MappedGolfCourseType['id'] | null;
  setActiveGolfCourseId: (id: MappedGolfCourseType['id']) => void;
  resetActiveGolfCourseId: () => void;
}

const createUISlice: Slice<CreateUISlice> = set => ({
  activeGolfCourseId: null,
  setActiveGolfCourseId: currentGolfCourseId => set({ activeGolfCourseId: currentGolfCourseId }),
  resetActiveGolfCourseId: () => set({ activeGolfCourseId: null })
});

export default createUISlice;
