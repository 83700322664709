import { useMutation, useQuery, useQueryClient } from 'react-query';

import * as Sentry from '@sentry/react';

import useStore from '../../../store/useStore';
import { get, put } from '../../http';
import { ProfileType } from './types';

const QUERY_KEY_PROFILE = ['profile', 'me'];

const getProfile = () => get<ProfileType>('v2/Profiles/Me');

const saveProfile = (data: Partial<ProfileType>) => put<ProfileType>('v2/Profiles/Me', data);

export const useProfile = () => {
  const { isLoggedIn } = useStore.getState();

  return useQuery(QUERY_KEY_PROFILE, getProfile, { enabled: isLoggedIn });
};

export const useUpdateProfile = () => {
  const queryClient = useQueryClient();

  return useMutation(saveProfile, {
    onSuccess: profile => {
      queryClient.setQueryData(QUERY_KEY_PROFILE, profile);
    },
  });
};

export const useClearProfile = () => {
  const queryClient = useQueryClient();

  return useMutation(() => {
    queryClient.removeQueries(QUERY_KEY_PROFILE, { exact: true });
    Sentry.setUser(null);

    return Promise.resolve();
  });
};
