import { useMutation, useQuery, useQueryClient } from 'react-query';

import { del, get, post } from '../http';

const KEY = ['profile', 'friends'];

export const useFriends = () =>
  useQuery(KEY, async () => {
    const friends = await getFriends();
    return friends.map(f => ({
      ...f.friend,
      email: f.friend.mail,
      name: f.friend.firstName ? f.friend.firstName + ' ' + f.friend.lastName : null,
    }));
  });

export const useDeleteFriend = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteFriend, {
    onSuccess: (_, friendId) => {
      // queryClient.invalidateQueries(KEY);
      queryClient.setQueryData(KEY, friends => [...friends.filter(f => f.id !== friendId)]);
    },
  });
};

export const useAddFriend = () => {
  const queryClient = useQueryClient();
  return useMutation(addFriend, {
    onSuccess: response => {
      // queryClient.invalidateQueries(KEY);
      queryClient.setQueryData(KEY, friends => [...friends, response.friend]);
    },
  });
};

const getFriends = () => get('Userfriends/Me');
const deleteFriend = friendId => del(`Userfriends/${friendId}`);
const addFriend = friend => post('Userfriends', friend);
