import { stripAndClean } from '../../../helpers/stripAndClean';
import { latinizeReplace } from '../../language/latinize';
import extractGolfClubInfo from './extractGolfClubInfo';
import { GolfClubInfoType, GolfClubType, MappedGolfCourseType } from './types';

const mapGolfCourses = (
  activeGolfClubs: GolfClubType[],
  golfClubInfos: GolfClubInfoType[]
): MappedGolfCourseType[] => {
  const golfCourses: MappedGolfCourseType[] = [];

  activeGolfClubs.forEach(golfClub => {
    golfClub.golfCourses.forEach(golfCourse => {
      const golfClubInfo = golfClubInfos.find(i => i.golfClubId === golfClub.id);

      golfCourses.push({
        golfClub: {
          name: golfClub.name,
          id: golfClub.id,
          link: stripAndClean(golfClub.name),
        },
        country: golfClub.country,
        district: golfClub.district,
        information: golfClub.information,
        latitude: golfClub.latitude,
        longitude: golfClub.longitude,
        // TODO calculated dynamically here: src/pages/booking/index.tsx
        distance: null,
        golfCourses: golfClub.golfCourses,
        info: golfClubInfo ? extractGolfClubInfo(golfClubInfo) : {},
        id: `${golfClub.id}-${golfCourse.id}`,
        golfCourse: {
          name: golfCourse.name,
          id: golfCourse.id,
          numberOfHoles: golfCourse.numberOfHoles,
          link: stripAndClean(golfCourse.name),
          golfhaftetId: golfCourse.golfhaftetId,
        },
        name: `${golfClub.name} - ${golfCourse.name}`,
        search: `${golfClub.name
          .toLowerCase()
          .replace(/[^A-Za-z0-9[\] ]/g, latinizeReplace)} ${golfCourse.name
          .toLowerCase()
          .replace(/[^A-Za-z0-9[\] ]/g, latinizeReplace)}`,
      });
    });
  });

  return golfCourses.sort((a, b) => a.golfClub.name.localeCompare(b.golfClub.name));
};

export default mapGolfCourses;
